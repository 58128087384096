import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter
} from '@reduxjs/toolkit';

// Api calls for the Customer State slice
//
// Fetches the Customers from the server and returns it as json data
export const fetchCustomers = createAsyncThunk(
  '/customer/fetchCustomers',
  async () => {
    return await fetch(process.env.REACT_APP_API + '/customer').then((res) =>
      res.json()
    );
  }
);

export const editCustomer = createAsyncThunk(
  '/customer/editCustomer',
  async ({ cid, editPayload }) => {
    await fetch(process.env.REACT_APP_API + '/customer?cid=' + cid, {
      method: 'PUT',
      body: JSON.stringify(editPayload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { cid, editPayload };
  }
);

export const createCustomer = createAsyncThunk(
  '/customer/createCustomer',
  async ({ createPayload }) => {
    await fetch(process.env.REACT_APP_API + '/customer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(createPayload)
    });
    return { createPayload };
  }
);

const customerAdapter = createEntityAdapter({
  selectId: (customer) => customer.customerId
});

const customerSlice = createSlice({
  name: 'customers',
  initialState: customerAdapter.getInitialState({
    loading: false,
    error: null
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCustomers.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCustomers.fulfilled, (state, { payload }) => {
        state.loading = false;
        customerAdapter.setAll(state, payload);
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(editCustomer.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editCustomer.fulfilled, (state, { payload }) => {
        state.loading = false;
        customerAdapter.updateOne(state, {
          id: payload.cid,
          changes: payload.editPayload
        });
      });
  }
});

export const {
  selectAll: selectAllCustomers,
  selectById: selectCustomerById,
  selectIds: selectCustomerIds
} = customerAdapter.getSelectors((state) => state.customers);

export default customerSlice.reducer;
