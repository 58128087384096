import React, { useContext, useEffect, useState } from "react";
import { auth } from "./Firebase";
import firebase from "firebase/app";
import "firebase/auth";

// Create context for Auth
const AuthContext = React.createContext();

// Make Globally available
export function useAuth() {
  return useContext(AuthContext);
}

// Provides auth for component routing
export default function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  // Login function from firebase auth
  function login(email, password) {
    auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    auth.signOut();
    
  }

  // Run on mount
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      // for the love of god set this so we dont have logout on refresh
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
