import React, { useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { DeleteForever, Edit, Add } from '@mui/icons-material';
import { editOrder } from '../redux/order/orderSlice';
import { useDispatch } from 'react-redux';
import { calcOrderTotal } from './helperFunctions';
import DeleteOrderConfDialog from './dialogs/DeleteOrderConfDialog';

// TO-DO
// Discounts added

// TO-DO
// add loading button to submit

// TO-DO
// add order shipping address over cust shipping address

const statusOptions = [
  { status: 'DRAFT', disabled: false },
  { status: 'SAVED', disabled: false },
  { status: 'PACKED', disabled: false },
  { status: 'SHIPPED', disabled: false },
  { status: 'ARCHIVED', disabled: false }
];

const TitleCell = ({ primary }) => {
  return (
    <Typography fontWeight={600} color="textSecondary">
      {primary}
    </Typography>
  );
};

const OrderDetailsCard = ({ order, orderLineItems, customer }) => {
  const [editMode, setEditMode] = useState(false);
  const [actionCount, setActionCount] = useState(0);
  const [openDeleteOrdDialog, setOpenDeleteOrdDialog] = useState(false);
  const [statusValue, setStatusValue] = useState(order.order_status);

  console.log(orderLineItems);
  console.log(order);

  const dispatch = useDispatch();

  const handleToggleEditMode = () => {
    setEditMode(false);
    setActionCount(0);
  };

  const handleDeleteOrderClick = () => {
    setOpenDeleteOrdDialog(true);
  };

  const handleDeleteOrdClose = () => {
    setOpenDeleteOrdDialog(false);
  };

  const handleStatusChange = (e) => {
    setStatusValue(e.target.value);
  };

  const handleSave = () => {
    if (order.order_status !== statusValue) {
      console.log('dispatching');
      console.log(statusValue);
      dispatch(
        editOrder({ order_id: order.order_id, order_status: statusValue })
      );
    }
    setActionCount(actionCount + 1);
  };

  const statusAvailabilityCheck = function (statusOptions, orderLineItems) {
    let options = [];
    if (orderLineItems.some((item) => item.status === 'PENDING')) {
      for (let i = 0; i < statusOptions.length; i++) {
        if (
          statusOptions[i].status === 'PACKED' ||
          statusOptions[i].status === 'SHIPPED'
        ) {
          statusOptions[i].disabled = true;
        }
      }
    }
    Object.fromEntries(
      Object.entries(statusOptions).filter(([key, value]) => {
        if (value.status !== order.status) {
          options.push(value);
        }
        return options;
      })
    );
    return options;
  };

  const options = statusAvailabilityCheck(statusOptions, orderLineItems);

  return (
    <>
      <Paper variant="outlined" sx={{ borderRadius: 2 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan="2">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h6" style={{ fontWeight: 600, py: 4 }}>
                      Details
                    </Typography>
                    {!editMode && (
                      <Tooltip arrow placement="left" title="Edit Order">
                        <Button
                          disableElevation
                          variant="contained"
                          onClick={() => setEditMode(true)}
                        >
                          <Edit fontSize="small" />
                        </Button>
                      </Tooltip>
                    )}
                    {editMode && (
                      <Button variant="outlined" onClick={handleToggleEditMode}>
                        {actionCount > 0 ? 'Done' : 'Cancel'}
                      </Button>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan="2">
                  <Grid container>
                    <Grid item xs={3}>
                      <TitleCell primary="Customer" />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography>{customer.billTo1}</Typography>
                      <Typography>{customer.billTo2}</Typography>
                      <Typography>{customer.billTo3}</Typography>
                      <Typography>{customer.billTo4}</Typography>
                      <Typography>{customer.billTo5}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2">
                  <Grid container>
                    <Grid item xs={3}>
                      <TitleCell primary="Shipping" />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography>{customer.shipTo1}</Typography>
                      <Typography>{customer.shipTo2}</Typography>
                      <Typography>{customer.shipTo3}</Typography>
                      <Typography>{customer.shipTo4}</Typography>
                      <Typography>{customer.shipTo5}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2">
                  <Grid container>
                    <Grid item xs={3}>
                      <TitleCell primary="ID" />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography>{order.order_id}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2">
                  <Grid container alignItems="center">
                    <Grid item xs={3}>
                      <TitleCell primary="Discounts" />
                    </Grid>
                    <Grid item xs={8}>
                      <Typography>{order.discount}</Typography>
                    </Grid>
                    {editMode && (
                      <Grid item xs={1}>
                        <Button
                          sx={{ pr: 1 }}
                          size="medium"
                          startIcon={<Add />}
                        >
                          <Typography
                            fontWeight={600}
                            sx={{ textTransform: 'none' }}
                          >
                            Add
                          </Typography>
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2">
                  <Grid container>
                    <Grid item xs={3}>
                      <TitleCell primary="Total" />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography>
                        ${calcOrderTotal(order, orderLineItems)}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2">
                  <Grid container>
                    <Grid item xs={3}>
                      <TitleCell primary="Order Status" />
                    </Grid>
                    <Grid item xs={8}>
                      <FormControl>
                        {editMode ? (
                          <Select
                            fullWidth
                            native
                            variant="outlined"
                            onChange={(e) => handleStatusChange(e)}
                            value={statusValue}
                            disabled={editMode ? false : true}
                            sx={{ width: '100%' }}
                          >
                            <option value={order.order_status}>
                              {order.order_status}
                            </option>
                            {options.map((option, idx) => (
                              <option
                                key={idx}
                                value={option.status}
                                disabled={option.disabled}
                                label={option.status}
                              />
                            ))}
                          </Select>
                        ) : (
                          <Typography variant="h6">
                            {order.order_status}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>
                    {editMode && (
                      <Grid item xs={1}>
                        <Button
                          disabled={
                            statusValue === order.order_status ||
                            statusValue === ''
                          }
                          onClick={handleSave}
                          variant="contained"
                        >
                          <Typography
                            fontWeight={600}
                            sx={{ textTransform: 'none' }}
                          >
                            Save
                          </Typography>
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
              {editMode && (
                <TableRow>
                  <TableCell colSpan="2">
                    <Button
                      onClick={handleDeleteOrderClick}
                      variant="text"
                      disableElevation
                      color="error"
                      startIcon={<DeleteForever />}
                    >
                      <Typography
                        sx={{ textTransform: 'none', fontWeight: 600 }}
                      >
                        Delete Order
                      </Typography>
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <DeleteOrderConfDialog
        open={openDeleteOrdDialog}
        onClose={handleDeleteOrdClose}
        orderToDelete={order}
      />
    </>
  );
};

export default OrderDetailsCard;
