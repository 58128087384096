// Reused Functions

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
/*eslint-disable*/
export const calcOrderTotal = function (order, lineItems) {
  let total = 0;

  for (const [_, value] of Object.entries(lineItems)) {
    total = total + Number(value.total);
  }

  formatter.format(total);
  return total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const calcOverviewOrderTotal = function (order_id, lineItems) {
  let total = 0;

  for (const [_, value] of Object.entries(lineItems)) {
    if (value.order_id === order_id) {
      total = total + Number(value.total);
    }
  }

  formatter.format(total);
  return total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};
/*eslint-enable*/
