import React from "react";
import { useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/auth/AuthContext";

// Components
import DashboardAppBar from "./DashboardAppBar";
import DashboardDrawer from "./DashboardDrawer";
import MainContent from "./MainContent";

// Width for drawer
const drawerWidth = 280;

export default function AdminDrawer(props) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { children } = props;

  async function handleLogout() {
    try {
      await logout();
      navigate("/login");
    } catch {}
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    navigate("/account");
    setAnchorEl(null);
  };

  const { currentUser } = useAuth();

  return (
    <Grid container>
      <DashboardAppBar
        handleDrawerToggle={handleDrawerToggle}
        handleLogout={handleLogout}
        handleMenu={handleMenu}
        handleProfileClick={handleProfileClick}
        currentUser={currentUser}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
      <DashboardDrawer
        drawerWidth={drawerWidth}
        handleDrawerToggle={handleDrawerToggle}
        handleClose={handleClose}
        mobileOpen={mobileOpen}
      />
      <MainContent children={children} drawerWidth={drawerWidth} />
    </Grid>
  );
}
