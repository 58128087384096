import { combineReducers } from 'redux';
import userReducer from './user/userSlice';
import orderReducer from './order/orderSlice';
import itemReducer from './item/itemSlice';
import customerReducer from './customer/customerSlice';

const rootReducer = combineReducers({
  customers: customerReducer,
  orders: orderReducer,
  user: userReducer,
  items: itemReducer
});

export default rootReducer;
