import React from 'react';
import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { deleteOrder } from '../../redux/order/orderSlice';
import { useNavigate } from 'react-router-dom';

const DeleteOrderConfDialog = ({ open, onClose, orderToDelete }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteOrder = (order_id) => {
    console.log('deleting order');
    dispatch(deleteOrder(order_id));
    navigate('/home');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Order</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to delete Order ${orderToDelete.order_id}? This action is permenant!`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleDeleteOrder(orderToDelete.order_id)}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrderConfDialog;
