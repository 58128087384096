import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  Grid,
  Paper,
  Stack,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import {
  ArrowForward,
  ContentPaste,
  Grass,
  NaturePeople,
  SupportAgent
} from '@mui/icons-material';
import { indigo, orange, red, teal, yellow } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

const cardData = [
  {
    title: 'Orders',
    description: 'Click to view order analytics.',
    link: '/analytics/orders',
    icon: (
      <ContentPaste color="inherit" fontSize="small" sx={{ padding: 'none' }} />
    ),
    offsetColor: teal[50],
    mainColor: teal[300]
  },
  {
    title: 'Items',
    description: 'Link to Item Analytics',
    link: '/analytics/items',
    icon: <Grass color="inherit" fontSize="small" sx={{ padding: 'none' }} />,
    offsetColor: orange[50],
    mainColor: orange[300]
  },
  {
    title: 'Customers',
    description: 'Link to Customer analytics',
    link: '/analytics/customers',
    icon: (
      <NaturePeople color="inherit" fontSize="small" sx={{ padding: 'none' }} />
    ),
    offsetColor: indigo[50],
    mainColor: indigo[300]
  },
  {
    title: 'Reps',
    description: 'Link to rep Analytics',
    link: '/analytics/reps',
    icon: (
      <SupportAgent color="inherit" fontSize="small" sx={{ padding: 'none' }} />
    ),
    offsetColor: red[50],
    mainColor: red[300]
  }
];

const GeneralAnalyticsOverview = () => {
  const [reps, setReps] = useState();

  const navigate = useNavigate();

  const getData = async () => {
    const data = await fetch(
      process.env.REACT_APP_API + '/order/recent?num=10'
    ).then((res) => res.json());
    setReps(data);
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(reps);

  const handleClick = (link) => {
    console.log('navigating');
    navigate(link);
  };

  return (
    <>
      <Grid container display="flex" flexDirection="row" spacing={2}>
        {cardData.map((card) => (
          <Grid item xs={12} md={6}>
            <Box
              component={Paper}
              p={2}
              sx={{ cursor: 'pointer' }}
              onClick={() => handleClick(card.link)}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={2}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    p={1.5}
                    sx={{
                      backgroundColor: card.offsetColor,
                      borderRadius: '6px'
                    }}
                  >
                    <Box
                      p={1.5}
                      sx={{
                        backgroundColor: card.mainColor,
                        borderRadius: '6px',
                        alignSelf: 'center'
                      }}
                    >
                      {card.icon}
                    </Box>
                  </Box>
                  <Stack>
                    <Typography variant="h5" fontWeight={600}>{card.title}</Typography>
                    <Typography color="GrayText">{card.description}</Typography>
                  </Stack>
                </Stack>
                <Stack>
                  <ArrowForward />
                </Stack>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default GeneralAnalyticsOverview;
