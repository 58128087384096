import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { chargeCard } from "./PaymentFetch";
import MessageModal from "./MessageModal";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    textAlign: "center",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const Payment = ({ open, onClose }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [exp, onExpChange] = useState(new Date());
  const [number, onNumberChange] = useState();
  const [code, onCodeChnage] = useState();

  const [errors, setErrors] = useState([]);
  const [type, setType] = useState("");

  const [openMM, setOpenMM] = useState(false);

  const handlePaymentError = (messages) => {
    console.log("In error");
    const errorText = messages.map((err) => err.errorText);
    console.log(errorText);
    setType("Error");
    setErrors(errorText);
    setOpenMM(true);
  };

  const handlePaymentSuccess = (message) => {
    console.log("Payment success");
    console.log(message);
    setType("Success");
    setOpenMM(true);
  };

  const handleExpChange = (date) => {
    onExpChange(date);
  };

  const handleNumberChange = (e) => {
    onNumberChange(e.target.value);
  };

  const handleCodeChange = (e) => {
    console.log(e.target.value);
    onCodeChnage(e.target.value);
  };

  const onCloseAll = () => {
    setOpenMM(false);
    onClose();
  };

  const onCloseMM = () => {
    setOpenMM(false);
  };

  const handleSubmit = () => {
    const card = {
      number: number,
      exp: [exp.getFullYear(), exp.getMonth()].join("/"),
      code: code,
    };
    chargeCard("100", card)
      .then((res) => {
        console.log(res);
        if (res.messages.resultCode === "Ok") {
          handlePaymentSuccess("success");
        } else {
          handlePaymentError(res.transactionResponse.errors);
        }
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        handlePaymentError([
          { errorText: "Invalid card information, please try again." },
        ]);
      });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="title"
      aria-describedby="description"
    >
      <div style={modalStyle} className={classes.paper}>
        <MessageModal
          open={openMM}
          onClose={onCloseAll}
          onCloseMessage={onCloseMM}
          message={errors}
          type={type}
        />
        <Typography variant="h4">Card Information</Typography>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            id="standard-basic"
            label="Card Number"
            onChange={handleNumberChange}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disableToolbar
              variant="inline"
              format="yyyy/MM"
              margin="normal"
              id="date-picker-inline"
              label="Expiration"
              value={exp}
              onChange={handleExpChange}
              renderInput={(props) => <TextField {...props} />}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </LocalizationProvider>
          <TextField
            id="outlined-basic"
            label="CCV"
            onChange={handleCodeChange}
          />
        </form>
        <Button
          style={{ marginLeft: 10 }}
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </Modal>
  );
};

export default Payment;
