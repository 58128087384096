import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  Button
} from '@mui/material';
import { Box } from '@mui/system';
import numeral from 'numeral';
import { Formik } from 'formik';
import { ErrorSharp } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { editCustomer } from '../redux/customer/customerSlice';

const PropertyList = (props) => {
  const { children } = props;

  return <List disablePadding>{children}</List>;
};

const PropertyListItem = (props) => {
  const { align, children, disableGutters, value, label, ...other } = props;

  return (
    <ListItem
      sx={{
        px: disableGutters ? 0 : 3,
        py: 1.5
      }}
      {...other}
    >
      <ListItemText
        disableTypography
        primary={
          <Typography
            sx={{ minWidth: align === 'vertical' ? 'inherit' : 180 }}
            variant="subtitle2"
          >
            {label}
          </Typography>
        }
        secondary={
          <Box
            sx={{
              flex: 1,
              mt: align === 'vertical' ? 0.5 : 0
            }}
          >
            {children || (
              <Typography color="textSecondary" variant="body2">
                {value}
              </Typography>
            )}
          </Box>
        }
        sx={{
          display: 'flex',
          flexDirection: align === 'vertical' ? 'column' : 'row',
          my: 0
        }}
      />
    </ListItem>
  );
};

const CustomerDetailsTable = ({ customer, editMode, setEditMode }) => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const dispatch = useDispatch();

  const align = mdUp ? 'horizontal' : 'vertical';
  console.log(customer);
  return (
    <>
      <Formik
        initialValues={{
          accountNo: customer.accountNo || '',
          active: customer.active || '',
          altPhone: customer.altPhone || '',
          balance: customer.balance || '',
          balanceTotal: customer.balanceTotal || '',
          billTo1: customer.billTo1 || '',
          billTo2: customer.billTo2 || '',
          billTo3: customer.billTo3 || '',
          billTo4: customer.billTo4 || '',
          billTo5: customer.billTo5 || '',
          company: customer.company || '',
          creditLimit: customer.creditLimit || '',
          customer: customer.customer || '',
          customerId: customer.customerId || null,
          customerType: customer.customerType || 'EL',
          fax: customer.fax || '',
          firstName: customer.firstName || '',
          jobTitle: customer.jobTitle || '',
          lastName: customer.lastName || '',
          mainEmail: customer.mainEmail || '',
          mainPhone: customer.mainPhone || '',
          prefix: customer.prefix || '',
          primaryContact: customer.primaryContact || '',
          rep: customer.rep || '',
          resaleNum: customer.resaleNum || '',
          salesTaxCode: customer.salesTaxCode || '',
          secondaryContact: customer.secondaryContact || '',
          shipTo1: customer.shipTo1 || '',
          shipTo2: customer.shipTo2 || '',
          shipTo3: customer.shipTo3 || '',
          shipTo4: customer.shipTo4 || '',
          shipTo5: customer.shipTo5 || '',
          taxItem: customer.taxItem || '',
          terms: customer.terms || ''
        }}
        onSubmit={async (values) => {
          console.log(values);
          try {
            console.log('dispatching');
            dispatch(
              editCustomer({ cid: customer.customerId, editPayload: values })
            );
            setEditMode(false);
          } catch (error) {
            console.log(error.message);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            {!editMode && (
              <Stack spacing={3}>
                <Card>
                  <CardHeader title="Basic Details" />
                  <Divider />
                  <PropertyList>
                    <PropertyListItem
                      align={align}
                      divider
                      label="Account No."
                      value={values.accountNo}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Active"
                      value={values.active}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Company"
                      value={values.company}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Customer"
                      value={values.customer}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Customer Id"
                      value={values.customerId}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Customer Type"
                      value={values.customerType}
                    />

                    <PropertyListItem
                      align={align}
                      divider
                      label="Rep"
                      value={values.rep}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Terms"
                      value={values.terms}
                    />
                  </PropertyList>
                </Card>
                <Card>
                  <CardHeader title="Contact" />
                  <Divider />
                  <PropertyList>
                    <PropertyListItem
                      align={align}
                      divider
                      label="Primary Contact"
                      value={values.primaryContact}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Secondary Contact"
                      value={values.secondaryContact}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Prefix"
                      value={values.prefix}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="First Name"
                      value={values.firstName}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Last Name"
                      value={values.lastName}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Job Title"
                      value={values.jobTitle}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Main Phone"
                      value={values.mainPhone}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Alt Phone"
                      value={values.altPhone}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Main Email"
                      value={values.mainEmail}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Fax"
                      value={values.fax}
                    />
                  </PropertyList>
                </Card>
                <Card>
                  <CardHeader title="Addresses" />
                  <Divider />
                  <PropertyList>
                    <ListItem divider>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              minWidth: align === 'vertical' ? 'inherit' : 180
                            }}
                            variant="subtitle2"
                          >
                            Billing
                          </Typography>
                        }
                        secondary={
                          <Box
                            sx={{
                              flex: 1,
                              mt: align === 'vertical' ? 0.5 : 0
                            }}
                          >
                            <Typography>{values.billTo1}</Typography>
                            <Typography>{values.billTo2}</Typography>
                            <Typography>{values.billTo3}</Typography>
                            <Typography>{values.billTo4}</Typography>
                            <Typography>{values.billTo5}</Typography>
                          </Box>
                        }
                        sx={{
                          display: 'flex',
                          flexDirection:
                            align === 'vertical' ? 'column' : 'row',
                          my: 0
                        }}
                      />
                    </ListItem>
                    <ListItem divider>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              minWidth: align === 'vertical' ? 'inherit' : 180
                            }}
                            variant="subtitle2"
                          >
                            Shipping
                          </Typography>
                        }
                        secondary={
                          <Box
                            sx={{
                              flex: 1,
                              mt: align === 'vertical' ? 0.5 : 0
                            }}
                          >
                            <Typography>{values.shipTo1}</Typography>
                            <Typography>{values.shipTo2}</Typography>
                            <Typography>{values.shipTo3}</Typography>
                            <Typography>{values.shipTo4}</Typography>
                            <Typography>{values.shipTo5}</Typography>
                          </Box>
                        }
                        sx={{
                          display: 'flex',
                          flexDirection:
                            align === 'vertical' ? 'column' : 'row',
                          my: 0
                        }}
                      />
                    </ListItem>
                  </PropertyList>
                </Card>
                <Card>
                  <CardHeader title="Financals" />
                  <Divider />
                  <PropertyList>
                    <PropertyListItem
                      align={align}
                      divider
                      label="Balance"
                      value={numeral(values.balance).format('$0,0.00')}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Balance Total"
                      value={numeral(values.balanceTotal).format('$0,0.00')}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Credit Limit"
                      value={values.creditLimit}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Resale Number"
                      value={values.resaleNum}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Sales Tax Code"
                      value={values.salesTaxCode}
                    />
                    <PropertyListItem
                      align={align}
                      divider
                      label="Tax Item"
                      value={values.taxItem}
                    />
                  </PropertyList>
                </Card>
              </Stack>
            )}
            {editMode && (
              <Card>
                <CardHeader title="Edit Customer" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">
                        Basic Details
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.accountNo && ErrorSharp.accountNo
                        )}
                        fullWidth
                        helperText={touched.accountNo && ErrorSharp.accountNo}
                        label="Account No."
                        margin="dense"
                        name="billTo1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="accountNo"
                        value={values.accountNo}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.active && ErrorSharp.active)}
                        fullWidth
                        helperText={touched.active && ErrorSharp.active}
                        label="Active"
                        margin="dense"
                        name="active"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="active"
                        value={values.active}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.company && ErrorSharp.company)}
                        fullWidth
                        helperText={touched.company && ErrorSharp.company}
                        label="Company"
                        margin="dense"
                        name="company"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="company"
                        value={values.company}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.customer && ErrorSharp.customer)}
                        fullWidth
                        helperText={touched.customer && ErrorSharp.customer}
                        label="Customer"
                        margin="dense"
                        name="customer"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="customer"
                        value={values.customer}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.customer && ErrorSharp.customer)}
                        fullWidth
                        helperText={touched.customer && ErrorSharp.customer}
                        label="Customer"
                        margin="dense"
                        name="customer"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="customer"
                        value={values.customer}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.customerType && ErrorSharp.customerType
                        )}
                        fullWidth
                        helperText={
                          touched.customerType && ErrorSharp.customerType
                        }
                        label="Customer Type"
                        margin="dense"
                        name="customerType"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="customerType"
                        value={values.customerType}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.rep && ErrorSharp.rep)}
                        fullWidth
                        helperText={touched.rep && ErrorSharp.rep}
                        label="Rep"
                        margin="dense"
                        name="rep"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="rep"
                        value={values.rep}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.terms && ErrorSharp.terms)}
                        fullWidth
                        helperText={touched.terms && ErrorSharp.terms}
                        label="Terms"
                        margin="dense"
                        name="terms"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="terms"
                        value={values.terms}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Contact</Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.primaryContact && ErrorSharp.primaryContact
                        )}
                        fullWidth
                        helperText={
                          touched.primaryContact && ErrorSharp.primaryContact
                        }
                        label="Primary Contact"
                        margin="dense"
                        name="primaryContact"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="primaryContact"
                        value={values.primaryContact}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.secondaryContact &&
                            ErrorSharp.secondaryContact
                        )}
                        fullWidth
                        helperText={
                          touched.secondaryContact &&
                          ErrorSharp.secondaryContact
                        }
                        label="Secondary Contact"
                        margin="dense"
                        name="secondaryContact"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="secondaryContact"
                        value={values.secondaryContact}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.prefix && ErrorSharp.prefix)}
                        fullWidth
                        helperText={touched.prefix && ErrorSharp.prefix}
                        label="Prefix"
                        margin="dense"
                        name="prefix"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="prefix"
                        value={values.prefix}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.firstName && ErrorSharp.firstName
                        )}
                        fullWidth
                        helperText={touched.firstName && ErrorSharp.firstName}
                        label="First Name"
                        margin="dense"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="firstName"
                        value={values.firstName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.lastName && ErrorSharp.lastName)}
                        fullWidth
                        helperText={touched.lastName && ErrorSharp.lastName}
                        label="Last Name"
                        margin="dense"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="lastName"
                        value={values.lastName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.jobTitle && ErrorSharp.jobTitle)}
                        fullWidth
                        helperText={touched.jobTitle && ErrorSharp.jobTitle}
                        label="Job Title"
                        margin="dense"
                        name="jobTitle"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="jobTitle"
                        value={values.jobTitle}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.mainPhone && ErrorSharp.mainPhone
                        )}
                        fullWidth
                        helperText={touched.mainPhone && ErrorSharp.mainPhone}
                        label="Main Phone"
                        margin="dense"
                        name="mainPhone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="mainPhone"
                        value={values.mainPhone}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.altPhone && ErrorSharp.altPhone)}
                        fullWidth
                        helperText={touched.altPhone && ErrorSharp.altPhone}
                        label="Alt Phone"
                        margin="dense"
                        name="altPhone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="altPhone"
                        value={values.altPhone}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.mainEmail && ErrorSharp.mainEmail
                        )}
                        fullWidth
                        helperText={touched.mainEmail && ErrorSharp.mainEmail}
                        label="Main Email"
                        margin="dense"
                        name="mainEmail"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="mainEmail"
                        value={values.mainEmail}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.fax && ErrorSharp.fax)}
                        fullWidth
                        helperText={touched.fax && ErrorSharp.fax}
                        label="Fax"
                        margin="dense"
                        name="fax"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="fax"
                        value={values.fax}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Addresses</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Billing</Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.billTo1 && ErrorSharp.billTo1)}
                        fullWidth
                        helperText={touched.billTo1 && ErrorSharp.billTo1}
                        label="Bill To 1"
                        margin="dense"
                        name="billTo1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="billTo1"
                        value={values.billTo1}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.billTo2 && ErrorSharp.billTo2)}
                        fullWidth
                        helperText={touched.billTo2 && ErrorSharp.billTo2}
                        label="Bill To 2"
                        margin="dense"
                        name="billTo2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="billTo2"
                        value={values.billTo2}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.billTo3 && ErrorSharp.billTo3)}
                        fullWidth
                        helperText={touched.billTo3 && ErrorSharp.billTo3}
                        label="Bill To 3"
                        margin="dense"
                        name="billTo3"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="billTo3"
                        value={values.billTo3}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.billTo4 && ErrorSharp.billTo4)}
                        fullWidth
                        helperText={touched.billTo4 && ErrorSharp.billTo4}
                        label="Bill To 4"
                        margin="dense"
                        name="billTo4"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="billTo4"
                        value={values.billTo4}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.billTo5 && ErrorSharp.billTo5)}
                        fullWidth
                        helperText={touched.billTo5 && ErrorSharp.billTo5}
                        label="Bill To 5"
                        margin="dense"
                        name="billTo5"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="billTo5"
                        value={values.billTo5}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Shipping</Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.shipTo1 && ErrorSharp.shipTo1)}
                        fullWidth
                        helperText={touched.shipTo1 && ErrorSharp.shipTo1}
                        label="Ship To 1"
                        margin="dense"
                        name="shipTo1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="shipTo1"
                        value={values.shipTo1}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.shipTo2 && ErrorSharp.shipTo2)}
                        fullWidth
                        helperText={touched.shipTo2 && ErrorSharp.shipTo2}
                        label="Ship To 2"
                        margin="dense"
                        name="shipTo2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="shipTo2"
                        value={values.shipTo2}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.shipTo3 && ErrorSharp.shipTo3)}
                        fullWidth
                        helperText={touched.shipTo3 && ErrorSharp.shipTo3}
                        label="Ship To 3"
                        margin="dense"
                        name="shipTo3"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="shipTo3"
                        value={values.shipTo3}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.shipTo4 && ErrorSharp.shipTo4)}
                        fullWidth
                        helperText={touched.shipTo4 && ErrorSharp.shipTo4}
                        label="Ship To 4"
                        margin="dense"
                        name="shipTo4"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="shipTo4"
                        value={values.shipTo4}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.shipTo5 && ErrorSharp.shipTo5)}
                        fullWidth
                        helperText={touched.shipTo5 && ErrorSharp.shipTo5}
                        label="Ship To 5"
                        margin="dense"
                        name="shipTo5"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="shipTo5"
                        value={values.shipTo5}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="textSecondary">Financials</Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.creditLimit && ErrorSharp.creditLimit
                        )}
                        fullWidth
                        helperText={
                          touched.creditLimit && ErrorSharp.creditLimit
                        }
                        label="Credit Limit"
                        margin="dense"
                        name="creditLimit"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="creditLimit"
                        value={values.creditLimit}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.resaleNum && ErrorSharp.resaleNum
                        )}
                        fullWidth
                        helperText={touched.resaleNum && ErrorSharp.resaleNum}
                        label="Resale Num."
                        margin="dense"
                        name="resaleNum"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="resaleNum"
                        value={values.resaleNum}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.salesTaxCode && ErrorSharp.salesTaxCode
                        )}
                        fullWidth
                        helperText={
                          touched.salesTaxCode && ErrorSharp.salesTaxCode
                        }
                        label="Sales Tax Code"
                        margin="dense"
                        name="salesTaxCode"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="salesTaxCode"
                        value={values.salesTaxCode}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.taxItem && ErrorSharp.taxItem)}
                        fullWidth
                        helperText={touched.taxItem && ErrorSharp.taxItem}
                        label="Tax Item"
                        margin="dense"
                        name="taxItem"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="taxItem"
                        value={values.taxItem}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="space-between">
                        <Button onClick={() => setEditMode(false)}>
                          Cancel
                        </Button>
                        <Stack
                          direction="row"
                          spacing={3}
                          justifyContent="flex-end"
                        >
                          <Button variant="text" type="reset">
                            Reset
                          </Button>
                          <Button
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Save
                          </Button>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

export default CustomerDetailsTable;
