import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAuth } from '../components/auth/AuthContext';
import { batch, useDispatch } from 'react-redux';
import { fetchCustomers } from '../redux/customer/customerSlice';
import { NewFeaturesBanner } from '../components/NewFeaturesBanner';
import GeneralAnalyticsOverview from '../components/analytics/GeneralAnalyticsOverview';
import { getUserInfo } from '../redux/user/userSlice';

const AdminDashboardPage = () => {
  const [displayBanner, setDisplayBanner] = useState(true);
  const { currentUser } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Dashboard';
    const value = window.sessionStorage.getItem('dismiss-banner');

    if (value === 'true') {
      setDisplayBanner(false);
    }
    batch(() => {
      console.log('getting user');
      dispatch(getUserInfo(currentUser.uid));
      dispatch(fetchCustomers());
    });
  }, [dispatch, currentUser.uid]);

  const handleDismissBanner = () => {
    // Update the persistent state
    // window.sessionStorage.setItem('dismiss-banner', 'true');
    setDisplayBanner(false);
  };

  const getGreeting = () => {
    var today = new Date();
    var time = today.getHours();
    let greeting;
    if (time < 12) {
      greeting = 'Good Morning';
    } else if (time < 17) {
      greeting = 'Good Afternoon';
    } else {
      greeting = 'Good Evening';
    }
    return greeting;
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: 'background.default',
          minHeight: 'calc(100vh - 64px)',
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Box sx={{ mb: 4 }}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  style={{ fontWeight: '600' }}
                >
                  {getGreeting() +
                    `, ${
                      currentUser.displayName
                        ? currentUser.displayName.split(' ', 1)
                        : ''
                    }`}
                </Typography>
                <Typography color="textSecondary">
                  Check out what's new!
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={4}>
            {displayBanner && (
              <Grid item xs={12}>
                <NewFeaturesBanner onDismiss={handleDismissBanner} />
              </Grid>
            )}
            <Grid item xs={12}>
              <GeneralAnalyticsOverview />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default AdminDashboardPage;
