import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import AddItemsToOrderTable from '../AddItemsToOrderTable';

const AddItemToOrderDialog = ({ open, onClose, order }) => {
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle>Add Items To Order</DialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
        <AddItemsToOrderTable closeItemTable={onClose} order={order} />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddItemToOrderDialog;
