import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Grid,
  TablePagination,
  InputAdornment,
  Card,
  Box,
  Stack
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import OrderViewButton from '../components/OrderViewButton';
import { calcOverviewOrderTotal } from '../components/helperFunctions';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { convertToDateString } from '../utils/convertToDateString';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from './auth/AuthContext';
import { StatusPill } from './StatusPill';

//  TO-DO
//  Convert table head into responsive grid system

const headerCells = ['Order', 'Customer', 'Created By', 'Total', 'Status'];

const OrdersTable = ({ orders, lineItems }) => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [allOrderView, setAllOrderView] = useState(true);
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = useState('');

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const orderLoading = useSelector((state) => state.orders.loading);

  const changePage = (order) => {
    console.log('diving into single Order');
    const params = { oId: order.order_id };
    navigate({
      pathname: '/orders/view-order',
      search: `?${createSearchParams(params)}`
    });
  };

  function filterOrders(orders) {
    let visOrders = orders;

    if (!allOrderView) {
      visOrders = orders.filter((ord) => ord.email === currentUser.email);
    }

    const filteredOrders = visOrders.filter((order) =>
      order.customer.toLowerCase().includes(search)
    );
    return filteredOrders;
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card sx={{ m: 2 }}>
      <TableContainer>
        <Box sx={{ p: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <TextField
              variant="outlined"
              placeholder="Search Orders"
              type="search"
              value={search}
              sx={{ width: '40%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="inherit" />
                  </InputAdornment>
                )
              }}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
            <OrderViewButton
              setAllOrderView={setAllOrderView}
              allOrderView={allOrderView}
            />
          </Stack>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              {headerCells.map((cell) => (
                <TableCell key={cell} sx={{ fontWeight: 600 }}>
                  {cell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filterOrders(orders)
              .reverse()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((order) => (
                <TableRow
                  hover
                  key={order.order_id}
                  onClick={() => changePage(order)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <Grid container direction="column" spacing={0}>
                      <Grid item>
                        {orderLoading ? (
                          <Skeleton />
                        ) : (
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: 600 }}
                            color="textPrimary"
                          >
                            {`# ${order.order_id}`}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" color="textSecondary">
                          {orderLoading ? (
                            <Skeleton />
                          ) : (
                            convertToDateString(order.order_date)
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="body1" color="textPrimary">
                          {orderLoading ? <Skeleton /> : order.customer}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="body1" color="textPrimary">
                          {orderLoading ? <Skeleton /> : order.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {orderLoading ? (
                      <Skeleton />
                    ) : (
                      <Typography>
                        ${calcOverviewOrderTotal(order.order_id, lineItems)}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {orderLoading ? (
                      <Skeleton />
                    ) : (
                      <StatusPill>{order.order_status}</StatusPill>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        count={filterOrders(orders).length}
        component="div"
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default OrdersTable;
