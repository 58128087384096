import React, { useEffect, useState } from 'react';
import {
  InputAdornment,
  Select,
  TableRow,
  TextField,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TablePagination,
  Card,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import {
  fetchCustomers,
  selectAllCustomers
} from '../redux/customer/customerSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { StatusPill } from './StatusPill';

const headerCells = [
  'Name',
  'Phone Number',
  'Email Address',
  'Rep',
  'Balance',
  'Status'
];

// TO-DO
// make row cells typography tags

const CustomerTable = () => {
  document.title = 'Customers';
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const customers = useSelector(selectAllCustomers);
  const navigate = useNavigate();
  const customersStatus = useSelector((state) => state.customers.customers);
  const reps = [...new Set(customers.map((rep) => rep.rep))];

  useEffect(() => {
    if (customersStatus === 'idle') {
      dispatch(fetchCustomers());
    }
  }, [dispatch, customersStatus]);

  const handleChange = (e) => {
    let val = e.target.value;
    setValue(val);
  };

  const changePage = (customer) => {
    console.log('diving into single customer');
    const params = { custId: customer.customerId };
    navigate({
      pathname: `/customers/customer`,
      search: `?${createSearchParams(params)}`
    });
  };

  function filterCustomers(customers) {
    let visCustomers = customers;
    if (value) {
      visCustomers = customers.filter((customer) => customer.rep === value);
    }

    const filterCustomers = visCustomers.filter(
      (customer) =>
        customer.customer !== null &&
        customer.customer.toLowerCase().includes(search)
    );
    return filterCustomers;
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      customers ? filterCustomers(customers).length : 0 - page * rowsPerPage
    );

  return (
    <>
      <Card>
        <TableContainer>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              p: 3
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              sx={{ width: '40%' }}
              placeholder="Search Customers"
              value={search}
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <Select
              native
              variant="outlined"
              label="here"
              value={value}
              type="search"
              sx={{ width: '40%' }}
              onChange={(e) => handleChange(e)}
            >
              <option value="">None</option>
              {reps.map((rep, idx) => (
                <option key={idx} value={rep}>
                  {rep}
                </option>
              ))}
            </Select>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                {headerCells.map((cell) => (
                  <TableCell sx={{ fontWeight: 600 }}>{cell}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filterCustomers(customers)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((customer, index) => (
                  <TableRow
                    hover
                    key={index}
                    onClick={() => changePage(customer)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>
                      <Typography>{customer.customer}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{customer.mainPhone}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{customer.mainEmail}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{customer.rep}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>${customer.balance}</Typography>
                    </TableCell>
                    <TableCell>
                      <StatusPill color="success">{customer.active}</StatusPill>
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              count={customers ? filterCustomers(customers).length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default CustomerTable;
