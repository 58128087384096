import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';

// Api calls for the Item slice
//
//
// Fetches the items in the db and returns json data
export const fetchItems = createAsyncThunk('/items/fetchItems', async () => {
  return await fetch(process.env.REACT_APP_API + '/item/all').then((res) =>
    res.json()
  );
});

export const fetchSingleItem = createAsyncThunk(
  '/items/fetchSingleItem',
  async (itemId) => {
    console.log('fetching single item');
    return await fetch(
      process.env.REACT_APP_API + '/item?itemId=' + itemId
    ).then((res) => res.json());
  }
);

export const editItem = createAsyncThunk(
  '/items/editItem',
  async ({ itemId, editPayload }) => {
    console.log('in item edit');
    console.log(itemId);
    await fetch(process.env.REACT_APP_API + '/item?itemId=' + itemId, {
      method: 'PUT',
      body: JSON.stringify(editPayload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { itemId, editPayload };
  }
);

export const addItem = createAsyncThunk(
  '/items/addItem',
  async ({ itemToAdd }) => {
    await fetch(process.env.REACT_APP_API + '/item', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(itemToAdd)
    });
  }
);

export const deleteItem = createAsyncThunk(
  '/items/deleteItem',
  async ({ itemId }) => {
    await fetch(process.env.REACT_APP_API + '/item?itemId=' + itemId, {
      method: 'DELETE'
    });
    return itemId;
  }
);

// Entity adapter for the item data
// sets items in normalized form
// Ids{} Entities{}
const itemAdapter = createEntityAdapter({
  selectId: (item) => item.itemId
});

// Slice for the item data
// error handling
//
// Can mutate state using adapter
const itemSlice = createSlice({
  name: 'items',
  initialState: itemAdapter.getInitialState({
    loading: true,
    error: null
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchItems.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchItems.fulfilled, (state, { payload }) => {
        state.loading = false;
        itemAdapter.setAll(state, payload);
      })
      .addCase(fetchSingleItem.pending, (state, action) => {
        state.loading = true;
        state.error = null;
        itemAdapter.removeAll(state);
      })
      .addCase(fetchSingleItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        itemAdapter.setOne(state, payload);
      })
      .addCase(editItem.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(editItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        itemAdapter.updateOne(state, {
          id: payload.itemId,
          changes: payload.editPayload
        });
      })
      .addCase(addItem.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(addItem.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteItem.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteItem.fulfilled, (state, { payload }) => {
        itemAdapter.removeOne(state, payload.itemId);
      });
  }
});

export const {
  selectAll: selectAllItems,
  selectById: selectItemById,
  selectIds: selectItemIds
} = itemAdapter.getSelectors((state) => state.items);

export default itemSlice.reducer;

// export const fetchItemsStartAsync = () => {
//   return (dispatch) => {
//     dispatch(fetchItemsStart());
//     fetch(process.env.REACT_APP_API + "/item")
//       .then((response) => response.json())
//       .then((res) => {
//         res.forEach((element) => {
//           var id = element["itemId"];
//           element["id"] = id;
//         });
//         //console.log(res);
//         dispatch(fetchItemsSuccess(res));
//       })
//       .catch((error) => dispatch(fetchItemsFailure(error.message)));
//   };
// };
