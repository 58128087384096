import React, { useState } from 'react';
import {
  Button,
  DialogActions,
  TextField,
  Box,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid
} from '@mui/material';
import { Formik } from 'formik';

// TO-DO
// Wire call and redux for customer edit on address

// Create conf dialog that fires save user call

const EditCustomerShippingAddressDialog = ({
  open,
  onClose,
  handleUpdate,
  customerInfo
}) => {
  //  const [saveAsNewAddr, setSaveAsNewAddr] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        shipTo1: customerInfo.shipTo1 || '',
        shipTo2: customerInfo.shipTo2 || '',
        shipTo3: customerInfo.shipTo3 || '',
        shipTo4: customerInfo.shipTo4 || '',
        shipTo5: customerInfo.shipTo5 || ''
      }}
    >
      {({ handleChange, handleSubmit, setFieldValue, values }) => (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              Edit Shipping Info: {customerInfo.billTo1}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={3} pt={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Ship To 1"
                    value={values.shipTo1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Ship To 2"
                    value={values.shipTo2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Ship To 3"
                    value={values.shipTo3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Ship To 4"
                    value={values.shipTo4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Ship To 5"
                    value={values.shipTo5}
                  />
                </Grid>
              </Grid>
              <Box sx={{ pt: 3, pl: 2 }}>
                <FormControlLabel
                  label="Save This Address as The Default Shipping Address"
                  control={<Checkbox defaultChecked />}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="text"
                type="reset"
                onClick={onClose}
                color="inherit"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={handleUpdate}
                disableElevation
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
};

export default EditCustomerShippingAddressDialog;
