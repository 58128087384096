import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {};

export const getUserInfo = createAsyncThunk(
  '/user/getUserInfo',
  async (uid) => {
    const userInfo = await fetch(
      process.env.REACT_APP_API + '/user?fbid=' + uid
    ).then((res) => res.json());
    console.log(userInfo);
    return userInfo;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUserInfo.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = 'error fetching user info';
      });
  }
});

export default userSlice.reducer;
