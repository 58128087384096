import React from 'react';
import {
  Button,
  Container,
  Stack,
  Typography,
  Grid,
  Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import ProductInventoryTable from '../components/ProductInventoryTable';
import { useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';

const ItemInventoryPage = () => {
  document.title = 'Product Inventory';

  const navigate = useNavigate();

  const changePage = () => {
    console.log('Navigating to Create Item Page');
    navigate('/create-item');
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          py: 8,
          minHeight: '100vh',
          overflow: 'hidden'
        }}
      >
        <Container maxWidth="xl">
          <Grid container display="flex" flexDirection="column">
            <Grid item>
              <Box sx={{ mb: 12 }}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h4" fontWeight={600}>
                      {document.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Stack direction="row" spacing={3}>
                      <Tooltip
                        arrow
                        title="Modify Quantity on Hand"
                        placement="left"
                      >
                        <Button size="large" variant="outlined">
                          <Typography sx={{ textTransform: 'none' }}>
                            Modify Inventory
                          </Typography>
                        </Button>
                      </Tooltip>
                      <Tooltip arrow title="Create Item" placement="bottom">
                        <Button
                          size="large"
                          variant="contained"
                          onClick={changePage}
                          startIcon={<Add />}
                        >
                          <Typography sx={{ textTransform: 'none' }}>
                            Create
                          </Typography>
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <ProductInventoryTable />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ItemInventoryPage;
