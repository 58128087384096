import React from 'react';
import {
  Button,
  Card,
  FormHelperText,
  TextField,
  Typography,
  Divider,
  Stack
} from '@mui/material';
import { Box } from '@mui/system';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { addItem } from '../redux/item/itemSlice';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// TO-DO
// Make Fields Required

// Make fields drop downs

const CreateItemForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <Card>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            minHeight: '100%'
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              activeStatus: '',
              type: '',
              description: '',
              salesTaxCode: '',
              account: '',
              cogsAccount: '',
              assetAccount: '',
              accumumulatedDepreciation: '',
              purchaseDescription: '',
              quantityOnHand: '',
              cost: '',
              preferredVendor: '',
              taxAgency: '',
              price: '',
              reorderPtMin: '',
              mpn: '',
              retailPrice: '',
              cs: '',
              discountAmount: '',
              imagePath: ''
            }}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                console.log('dispatching');
                await dispatch(addItem({ itemToAdd: values }));
                navigate('/product-inventory');
                enqueueSnackbar('Item Created', {
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                  },
                  variant: 'success'
                });
              } catch (error) {
                console.log(error.message);
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
                enqueueSnackbar(error.message, {
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                  },
                  variant: 'error'
                });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box sx={{ p: 2 }}>
                  <Typography variant="h5" fontWeight={600}>
                    Item Details
                  </Typography>
                </Box>
                <Divider />
                <Stack sx={{ p: 2 }} spacing={2}>
                  <TextField
                    error={Boolean(touched.account && errors.account)}
                    fullWidth
                    helperText={touched.account && errors.account}
                    label="Account"
                    margin="dense"
                    name="account"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="account"
                    value={values.account}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(
                      touched.accumumulatedDepreciation &&
                        errors.accumumulatedDepreciation
                    )}
                    fullWidth
                    helperText={
                      touched.accumumulatedDepreciation &&
                      errors.accumumulatedDepreciation
                    }
                    label="accumumulatedDepreciation"
                    margin="dense"
                    name="accumumulatedDepreciation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="accumumulatedDepreciation"
                    value={values.accumumulatedDepreciation}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.activeStatus && errors.activeStatus)}
                    fullWidth
                    helperText={touched.activeStatus && errors.activeStatus}
                    label="Status"
                    margin="dense"
                    name="activeStatus"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="activeStatus"
                    value={values.activeStatus}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.assetAccount && errors.assetAccount)}
                    fullWidth
                    helperText={touched.assetAccount && errors.assetAccount}
                    label="Asset Account"
                    margin="dense"
                    name="assetAccount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="assetAccount"
                    value={values.assetAccount}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.cogsAccount && errors.cogsAccount)}
                    fullWidth
                    helperText={touched.cogsAccount && errors.cogsAccount}
                    label="Cogs Account"
                    margin="dense"
                    name="cogsAccount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="cogsAccount"
                    value={values.cogsAccount}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.cost && errors.cost)}
                    fullWidth
                    helperText={touched.cost && errors.cost}
                    label="Cost"
                    margin="dense"
                    name="cost"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="cost"
                    value={values.cost}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.cs && errors.cs)}
                    fullWidth
                    helperText={touched.cs && errors.cs}
                    label="cs"
                    margin="dense"
                    name="cs"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="cs"
                    value={values.cs}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    label="Description"
                    margin="dense"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="description"
                    value={values.description}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(
                      touched.discountAmount && errors.discountAmount
                    )}
                    fullWidth
                    helperText={touched.discountAmount && errors.discountAmount}
                    label="Discount Amount"
                    margin="dense"
                    name="discountAmount"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="discountAmount"
                    value={values.discountAmount}
                    variant="outlined"
                  />
                  <TextField
                    disabled
                    error={Boolean(touched.imagePath && errors.imagePath)}
                    fullWidth
                    helperText={touched.imagePath && errors.imagePath}
                    label="Image Path"
                    margin="dense"
                    name="imagePath"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="imagePath"
                    value={values.imagePath}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.item && errors.item)}
                    fullWidth
                    helperText={touched.item && errors.item}
                    label="Item"
                    margin="dense"
                    name="item"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="item"
                    value={values.item}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.mpn && errors.mpn)}
                    fullWidth
                    helperText={touched.mpn && errors.mpn}
                    label="mpn"
                    margin="dense"
                    name="mpn"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="mpn"
                    value={values.mpn}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(
                      touched.preferredVendor && errors.preferredVendor
                    )}
                    fullWidth
                    helperText={
                      touched.preferredVendor && errors.preferredVendor
                    }
                    label="Preferred Vendor"
                    margin="dense"
                    name="preferredVendor"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="preferredVendor"
                    value={values.preferredVendor}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.price && errors.price)}
                    fullWidth
                    helperText={touched.price && errors.price}
                    label="Price"
                    margin="dense"
                    name="price"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="price"
                    value={values.price}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(
                      touched.purchaseDescription && errors.purchaseDescription
                    )}
                    fullWidth
                    helperText={
                      touched.purchaseDescription && errors.purchaseDescription
                    }
                    label="Purchase Description"
                    margin="dense"
                    name="purchaseDescription"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="purchaseDescription"
                    value={values.purchaseDescription}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(
                      touched.quantityOnHand && errors.quantityOnHand
                    )}
                    fullWidth
                    helperText={touched.quantityOnHand && errors.quantityOnHand}
                    label="Quantity on Hand"
                    margin="dense"
                    name="quantityOnHand"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="quantityOnHand"
                    value={values.quantityOnHand}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.reorderPtMin && errors.reorderPtMin)}
                    fullWidth
                    helperText={touched.reorderPtMin && errors.reorderPtMin}
                    label="Reorder Point"
                    margin="dense"
                    name="reorderPtMin"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="reorderPtMin"
                    value={values.reorderPtMin}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.retailPrice && errors.retailPrice)}
                    fullWidth
                    helperText={touched.retailPrice && errors.retailPrice}
                    label="Retail Price"
                    margin="dense"
                    name="retailPrice"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="retailPrice"
                    value={values.retailPrice}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.salesTaxCode && errors.salesTaxCode)}
                    fullWidth
                    helperText={touched.salesTaxCode && errors.salesTaxCode}
                    label="Sales Tax Code"
                    margin="dense"
                    name="salesTaxCode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="salesTaxCode"
                    value={values.salesTaxCode}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.taxAgency && errors.taxAgency)}
                    fullWidth
                    helperText={touched.taxAgency && errors.taxAgency}
                    label="Tax Agency"
                    margin="dense"
                    name="taxAgency"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="taxAgency"
                    value={values.taxAgency}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.type && errors.type)}
                    fullWidth
                    helperText={touched.type && errors.type}
                    label="Type"
                    margin="dense"
                    name="type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="type"
                    value={values.type}
                    variant="outlined"
                  />
                </Stack>
                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
                <Stack sx={{ p: 2 }} direction="row" justifyContent="flex-end">
                  <Button type="reset">Reset</Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        </Box>
      </Card>
    </>
  );
};

export default CreateItemForm;
