import React from 'react';
import { useSelector } from 'react-redux';
import {
  TextField,
  Autocomplete,
  Paper,
  Box,
  Avatar,
  Typography
} from '@mui/material';
import { selectAllCustomers } from '../redux/customer/customerSlice';
import generateAvatarLetters from '../utils/generateAvatarLetters';

const CustomerSelectionAutoComplete = ({
  setCustomerInfo,
  openSide,
  setOpenSide
}) => {
  const customers = useSelector(selectAllCustomers);

  const handleSelectCustomer = (value) => {
    setCustomerInfo(value);
    setOpenSide(!openSide);
  };

  console.log(customers);

  return (
    <>
      <Paper>
        <Autocomplete
          onChange={(event, value) => handleSelectCustomer(value)}
          id="combo-box-demo"
          options={customers}
          getOptionLabel={(option) => option.customer}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Customers"
              placeholder="Search Customers"
              variant="outlined"
            />
          )}
        />
      </Paper>
    </>
  );
};

export default CustomerSelectionAutoComplete;

// renderOption={(props, option) => (
//   <Box {...props}>
//     <Box display="flex" flexDirection="row" mr={1}>
//       <Avatar />
//     </Box>
//     <Box>
//       <Typography color="textPrimary">{option.customer}</Typography>
//       <Typography variant="subtitle2" color="textSecondary">
//         {option.mainEmail}
//       </Typography>
//     </Box>
//   </Box>
// )}
