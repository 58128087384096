import React, { useState } from "react";
import {
  Box,
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { initialState } from "../redux/shipping/shippingSlice.js";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

import Fab from "@mui/material/Fab";

import styled from "@emotion/styled";
import { green } from "@mui/material/colors";

import { Formik } from "formik";
import * as Yup from "yup";
import Stack from "@mui/material/Stack";

export const StyleWrapper = styled.div`
  .fc .fc-view-harness {
    background: white;
    border: 1px solid #eeeeee;
    border-radius: 12px;
  }
  .fc-view {
    //margin: 24px;
  }
`;

const useStyles = makeStyles((theme) => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: "12px",
    },
  },
}));

const ShippingSchedule = () => {
  document.title = "Shipping Schedule";

  const classes = useStyles();
  const [weekendsVisible, setWeekendsVisible] = useState(true);
  const [open, setOpen] = useState(false);

  const handleWeekendsToggle = () => {
    setWeekendsVisible(!weekendsVisible);
  };

  const handleDateSelect = (selectInfo) => {
    setOpen(true);
    let title = "";
    let calendarApi = selectInfo.view.calendar;
    console.log("in date select");
    console.log(calendarApi);

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: 1,
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  };

  const handleEventClick = (clickInfo) => {
    if (
      window.confirm(
        `Do you want to Make changes to: ${clickInfo.event.title}?`
      )
    ) {
      clickInfo.event.remove();
    }
  };

  const handleEvents = (events) => {};

  const handleClose = () => {
    setOpen(false);
  };

  function renderEventContent(eventInfo) {
    return (
      <div>
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </div>
    );
  }

  return (
    <Box sx={{ mx: 2, mt: 6, mb: 2 }}>
      <CssBaseline>
        <Dialog open={open}>
          <DialogTitle>Add Event</DialogTitle>
          <Formik
            initialValues={{
              title: "",
              driver: "",
            }}
            validationSchema={Yup.object({
              title: Yup.string().max(30).required("Event Title is Required"),
            })}
            onSubmit={(values, selectInfo) => {
              console.log(values);

              setOpen(false);
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <DialogContent dividers>
                  <Stack spacing={2}>
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      id="title"
                      name="title"
                      placeholder="Title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                    />
                    <TextField
                      className={classes.textField}
                      variant="outlined"
                      id="driver"
                      name="driver"
                      placeholder="Driver"
                    />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button type="submit" variant="contained" color="primary">
                    Add Event
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Dialog>

        <FullCalendar
          plugins={[
            listPlugin,
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
          ]}
          initialView="dayGridMonth"
          headerToolbar={{
            start: "title",
            center: "prev,today,next",
            end: "dayGridMonth,timeGridWeek,timeGrid,list",
          }}
          eventColor={green[700]}
          nowIndicator
          navLinks
          editable={true}
          selectable={true}
          selectMirror={true}
          initialEvents={initialState}
          weekends={weekendsVisible}
          select={handleDateSelect}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          eventsSet={handleEvents} // called after events are initialized/added/changed/removed
          //eventAdd={() => handleEventAdd}
          /* you can update a remote database when these fire:
            eventChange={function(){}}
            eventRemove={function(){}}
            */
        />
        <Fab
          onClick={handleWeekendsToggle}
          sx={{
            position: "fixed",
            bottom: (theme) => theme.spacing(8),
            right: (theme) => theme.spacing(8),
            zIndex: 1000,
          }}
          color="primary"
          variant="extended"
        >
          <Typography color="white" sx={{ textTransform: "none" }}>
            Toggle Weekends
          </Typography>
        </Fab>
      </CssBaseline>
    </Box>
  );
};

export default ShippingSchedule;
