import React from 'react';
import { Button, Container, Typography, Grid, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { Add } from '@mui/icons-material';
import CustomerTable from '../components/CustomerTable';
import { useNavigate } from 'react-router-dom';

const CustomersPage = () => {
  document.title = 'Customers';
  const navigate = useNavigate();

  const changePage = () => {
    console.log('Navigating to Create Customer Page');
    navigate('/create-customer');
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          py: 8,
          minHeight: '100vh',
          overflow: 'hidden'
        }}
      >
        <Container maxWidth="xl">
          <Grid container display="flex" flexDirection="column">
            <Grid item>
              <Box sx={{ mb: 12 }}>
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h4" fontWeight={600}>
                      {document.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Tooltip arrow title="Create Customer" placement="bottom">
                      <Button
                        size="large"
                        variant="contained"
                        onClick={changePage}
                        startIcon={<Add />}
                      >
                        <Typography sx={{ textTransform: 'none' }}>
                          Create
                        </Typography>
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CustomerTable />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CustomersPage;
