import {
  Avatar,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import { useAuth } from "../components/auth/AuthContext";
import { Box } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tableContainer: {
    border: `1px solid text.disabled`,
    borderRadius: "12px",
  },
  logo: {
    height: "64px",
  },
  header: {
    marginTop: "6vh",
  },
  roundCornerOutline: {
    marginTop: "2vh",
    border: "1px solid #eeeeee",
    borderRadius: "12px",
    backgroundColor: "white",
  },
  steps: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "12px",
  },
  show: {
    display: "block",
  },
  hide: {
    display: "none",
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: "12px",
    },
  },
}));

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  firstName: yup.string("Enter a first name"),
});

const UserAccount = () => {
  const { currentUser } = useAuth();
  console.log(currentUser);
  const classes = useStyles();

  document.title = "Account";

  let name = currentUser.displayName ? currentUser.displayName.split(" ") : "";
  let firstName = currentUser.displayName ? name[0] : "";
  let lastName = currentUser.displayName ? name[1] : "";

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  console.log(selectedImage);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const formik = useFormik({
    initialValues: {
      email: `${currentUser.email}`,
      firstName: `${firstName}`,
      lastName: `${lastName}`,
      photoUrl: `${currentUser.photoUrl ? currentUser.photoUrl : <Avatar />}`,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let displayName = [values.firstName, values.lastName].join(" ");
      let photoUrl = values.photoUrl;
      console.log(photoUrl);
      currentUser.updateProfile({
        displayName: displayName,
        photoUrl: photoUrl,
      });
    },
  });

  return (
    <Container maxWidth="md" sx={{ pt: 6 }}>
      <Typography variant="h4" style={{ fontWeight: "600", marginBottom: 8 }}>
        {document.title}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
        <Tabs>
          <Tab label="General" />
          <Tab label="Team" />
          <Tab />
        </Tabs>
      </Box>

      <Box component={Paper} variant="outlined" elevation={0} p={2}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Box sx={{ alignSelf: "center" }}>
              <Avatar sx={{ width: 112, height: 112, mb: 2 }} src={imageUrl} />
              <input
                id="select-image"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => setSelectedImage(e.target.files[0])}
              />
              <label htmlFor="select-image">
                <Button variant="contained" component="span">
                  Upload Image
                </Button>
              </label>
            </Box>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                id="firstName"
                name="firstName"
                variant="outlined"
                placeholder="Name"
                label="First Name"
                className={classes.textField}
                onChange={formik.handleChange}
                value={formik.values.firstName}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
              <TextField
                fullWidth
                id="lastName"
                name="lastName"
                variant="outlined"
                placeholder="Name"
                label="Last Name"
                className={classes.textField}
                onChange={formik.handleChange}
                value={formik.values.lastName}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Stack>
            <TextField
              fullWidth
              id="email"
              name="email"
              variant="outlined"
              disabled
              label="Email Address"
              className={classes.textField}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              onChange={formik.handleChange}
              sx={{ marginTop: 2 }}
            />
            <Box mt={2} alignSelf="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!formik.dirty}
              >
                Save Changes
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default UserAccount;

// <Grid
// container
// direction="column"
// justifyItems="baseline"
// component={Paper}
// elevation={0}
// >
// <Grid item lg={12}>
//   <Grid
//     container
//     direction="row"
//     columns={2}
//     justifyContent="space-between"
//   >
//     <Grid item>
//       <Avatar sx={{ width: 112, height: 112 }} />
//       <input
//         id="select-image"
//         type="file"
//         accept="image/*"
//         style={{ display: "none" }}
//         onChange={(e) => setSelectedImage(e.target.files[0])}
//       />
//       <label htmlFor="select-image">
//         <Button variant="contained" component="span">
//           Upload Image
//         </Button>
//       </label>
//     </Grid>
//     <Grid item>
//       <form onSubmit={formik.handleSubmit}>
//         <Stack spacing={2}>
//           <TextField
//             fullWidth
//             id="firstName"
//             name="firstName"
//             variant="outlined"
//             placeholder="Name"
//             label="First Name"
//             className={classes.textField}
//             onChange={formik.handleChange}
//             value={formik.values.firstName}
//             error={
//               formik.touched.firstName &&
//               Boolean(formik.errors.firstName)
//             }
//             helperText={
//               formik.touched.firstName && formik.errors.firstName
//             }
//           />
//           <TextField
//             fullWidth
//             id="lastName"
//             name="lastName"
//             variant="outlined"
//             placeholder="Name"
//             label="Last Name"
//             className={classes.textField}
//             onChange={formik.handleChange}
//             value={formik.values.lastName}
//             error={
//               formik.touched.lastName && Boolean(formik.errors.lastName)
//             }
//             helperText={
//               formik.touched.lastName && formik.errors.lastName
//             }
//           />
//           <TextField
//             fullWidth
//             id="email"
//             name="email"
//             variant="outlined"
//             disabled
//             label="Email Address"
//             className={classes.textField}
//             value={formik.values.email}
//             error={formik.touched.email && Boolean(formik.errors.email)}
//             onChange={formik.handleChange}
//           />
//           <Button
//             type="submit"
//             variant="contained"
//             color="primary"
//             disabled={!formik.dirty}
//           >
//             Save Changes
//           </Button>
//           {imageUrl && selectedImage && (
//             <Box>
//               <div>Image Preview</div>
//               <img
//                 src={imageUrl}
//                 alt={selectedImage.name}
//                 height="100px"
//               />
//             </Box>
//           )}
//         </Stack>
//       </form>
//     </Grid>
//   </Grid>
// </Grid>
// </Grid>
