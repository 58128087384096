import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Stack, Divider, Typography } from '@mui/material';

import FolderIcon from '@mui/icons-material/Folder';
import PeopleIcon from '@mui/icons-material/People';
import MovingIcon from '@mui/icons-material/Moving';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

import Logo from '../img/TheEmeraldLeafLogoHigh-removebg-preview (1).png';
import { DashboardDrawerSection } from './DashboardDrawerSections';
import { Scrollbar } from './Scrollbar';

const sections = [
  {
    title: 'Dashboard',
    items: [
      {
        title: 'Home',
        path: '/home',
        icon: <HomeRoundedIcon />
      },
      {
        title: 'Finance',
        path: '/finance',
        icon: <MovingIcon />
      },
      {
        title: 'Logistics',
        path: '/logistics',
        icon: <InsertChartIcon />
      },
      {
        title: 'Invoices',
        path: '/invoices',
        icon: <ReceiptIcon />
      }
    ]
  },
  {
    title: 'Create',
    items: [
      {
        title: 'Create',
        path: 'create',
        icon: <NoteAddIcon />,
        children: [
          {
            title: 'Order',
            path: '/create-order'
          }
        ]
      }
    ]
  },
  {
    title: 'Management',
    items: [
      {
        title: 'Orders',
        path: 'orders',
        icon: <FolderIcon />,
        children: [
          {
            title: 'Draft',
            path: '/orders/draft-orders'
          },
          {
            title: 'Saved',
            path: '/orders/saved-orders'
          },
          {
            title: 'Packed',
            path: '/orders/packed-orders'
          },
          {
            title: 'Shipped',
            path: '/orders/shipped-orders'
          },
          {
            title: 'Archived',
            path: '/orders/archived'
          }
        ]
      },
      {
        title: 'Shipping',
        path: '/shipping',
        icon: <LocalShippingIcon />,
        children: [
          {
            title: 'Shipping Schedule',
            path: '/shipping-schedule'
          }
        ]
      },
      {
        title: 'Customers',
        path: '/customers',
        icon: <PeopleIcon />,
        children: [
          {
            title: 'Customers',
            path: '/customers'
          }
        ]
      }
    ]
  },
  {
    title: 'Inventory',
    items: [
      {
        title: 'Product Inventory',
        path: '/product-inventory',
        icon: <AssignmentOutlinedIcon />
      }
    ]
  }
];

const MenuItems = () => {
  const location = useLocation();

  return (
    <>
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <div>
            <Box pl={2} pt={2} pb={1}>
              <Link to="/home">
                <img src={Logo} height="64px" alt="Emerald Logo" />
              </Link>
            </Box>
            <Box
              mx={3}
              my={1}
              sx={{ borderRadius: '8px', backgroundColor: '#404e53' }}
            >
              <Stack>
                <Typography ml={2} mt={1} color="white" variant="body1">
                  The Emerald System
                </Typography>
                <Typography pl={2} pb={1} variant="body2" color="GrayText">
                  A Venable Technology
                </Typography>
              </Stack>
            </Box>
          </div>
          <Divider
            sx={{
              borderColor: '#2D3748',
              my: 3
            }}
          />
          <Box flexGrow={1}>
            {sections.map((section) => (
              <DashboardDrawerSection
                key={section.title}
                path={location.pathname}
                sx={{
                  mt: 2,
                  '& + &': {
                    mt: 2
                  }
                }}
                {...section}
              />
            ))}
          </Box>
        </Box>
      </Scrollbar>
    </>
  );
};

export default MenuItems;
