import React from 'react';
import { Box, Button, Dialog } from '@mui/material';
import { ArrowLeft } from '@mui/icons-material';
import { PDFViewer } from '@react-pdf/renderer';
import { InvoicePDF } from '../InvoicePDF';

const PreviewInvoiceDialog = ({
  order,
  customer,
  lineItems,
  open,
  setViewPDF
}) => {
  return (
    <Dialog fullScreen open={open}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background.default',
            p: 2
          }}
        >
          <Button
            startIcon={<ArrowLeft fontSize="small" />}
            onClick={() => setViewPDF(false)}
            variant="contained"
          >
            Back
          </Button>
        </Box>
        <Box flexGrow={1}>
          <PDFViewer height="100%" style={{ border: 'none' }} width="100%">
            <InvoicePDF
              order={order}
              lineItems={lineItems}
              customer={customer}
            />
          </PDFViewer>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PreviewInvoiceDialog;
