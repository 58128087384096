import { Box } from "@mui/system";
import React from "react";

const MainContent = (props) => {
  const { children, drawerWidth } = props;

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        height: `calc(100vh - 64px})`,
        overflow: "auto",
        width: { sm: `calc(100% - ${drawerWidth}px)` },
      }}
    >
      {children}
    </Box>
  );
};

export default MainContent;
