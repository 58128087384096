import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Paper, Stack } from '@mui/material';
import { Box } from '@mui/system';

import Logo from '../../img/TheEmeraldLeafLogoHigh-removebg-preview (1).png';
import Footer from '../Footer.jsx';
import { useAuth } from './AuthContext.jsx';

function LogIn() {
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showRequiredErrors, setshowRequiredErrors] = useState('');
  const navigate = useNavigate();

  const onFormInputChange = (setValue, setError, value, err) => {
    setValue(value);

    if (showRequiredErrors) {
      setError(err);
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      await login(email, password);
      navigate('/home');
    } catch {
      setEmailError('Failed to sign in');
    }
  }

  useEffect(() => {
    document.title = 'Log In';
    setshowRequiredErrors();
  }, []);

  return (
    <Box sx={{ position: 'relative', textAlign: 'center' }}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          backgroundImage:
            'url(https://images.unsplash.com/photo-1607774786991-24c71fff4a03?ixid=MnwxMjA3fDB8MHxwcm9maWxlLWxpa2VkfDE1fHx8ZW58MHx8fHw%3D&ixlib=rb-1.2.1&w=1000&q=80)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}
      >
        <Paper
          variant="elevation"
          elevation={12}
          sx={{ pt: 2, pb: 4, px: 4, borderRadius: 3 }}
        >
          <Box ml={4}>
            <img src={Logo} alt="logo" height={100} />
          </Box>
          <Stack>
            <Typography fontWeight={600} variant="h5">
              Log In
            </Typography>
            <Typography component="body1" color="GrayText" sx={{ pb: 2 }}>
              Sign in to ELM
            </Typography>
          </Stack>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            error={emailError}
            helperText={emailError ? 'Email cannot be empty' : ''}
            onChange={(e) =>
              onFormInputChange(
                setEmail,
                setEmailError,
                e.target.value,
                e.target.value.length === 0
              )
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={password}
            error={passwordError}
            helperText={passwordError ? 'Password name cannot be empty' : ''}
            onChange={(e) =>
              onFormInputChange(
                setPassword,
                setPasswordError,
                e.target.value,
                e.target.value.length === 0
              )
            }
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                    size="large"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Box sx={{ mt: 4, mb: 2 }}>
            <Button
              type="submit"
              fullWidth
              disableElevation
              variant="contained"
              color="primary"
              onClick={(e) => handleSubmit(e)}
            >
              <Typography
                color="white"
                sx={{ textTransform: 'none', fontWeight: 500 }}
              >
                Log In
              </Typography>
            </Button>
          </Box>
        </Paper>
      </Box>
      <Box sx={{ position: 'absolute', bottom: '5%', right: '40%' }}>
        <Footer />
      </Box>
    </Box>
  );
}

export default LogIn;
