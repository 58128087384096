import React from 'react';
import { Box, Card, Grid, Typography } from '@mui/material';

const cardInfo = [
  {
    title: 'Total Orders',
    value: 361
  },
  {
    title: 'Cash',
    value: 181
  },
  {
    title: 'Credit Card',
    value: 112
  },
  {
    title: 'Check',
    value: 18
  }
];

const OrderAnalyticsQuickStats = () => {
  return (
    <>
      <Grid container spacing={2}>
        {cardInfo.map((card) => (
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Card sx={{ py: 2 }}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap'
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <Box sx={{ ml: 3 }}>
                    <Typography
                      color="primary"
                      fontWeight={600}
                      noWrap
                      variant="h6"
                    >
                      {card.title}
                    </Typography>
                    <Typography variant="h4">{card.value}</Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default OrderAnalyticsQuickStats;
