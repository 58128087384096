import merge from 'lodash/merge';
import {
  createTheme as createMuiTheme,
  responsiveFontSizes
} from '@mui/material/styles';
import { THEMES } from '../constants';
import { lightThemeOptions } from './lightThemeOptions';
import { darkThemeOptions } from './darkThemeOptions';
import { baseThemeOptions } from './baseThemeOptions';

const baseOptions = {
  baseThemeOptions
};

const themesOptions = {
  [THEMES.LIGHT]: lightThemeOptions,
  [THEMES.DARK]: darkThemeOptions
};

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createMuiTheme(
    merge(
      {},
      baseOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16
          }
        })
      },
      {
        direction: config.direction
      }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
