import React from 'react';
import {
  Button,
  Container,
  Stack,
  Grid,
  Typography,
  Box,
  Link
} from '@mui/material';
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCustomerById } from '../redux/customer/customerSlice';
import { selectAllLineItems, selectOrderById } from '../redux/order/orderSlice';
import { ArrowBack, Receipt, Today } from '@mui/icons-material';
import { convertToDateString } from '../utils/convertToDateString';

import OrderDetailsCard from '../components/OrderDetailsCard';
import OrderLineItemsCard from '../components/OrderLineItemsCard';

const OrderDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const oId = searchParams.get('oId');

  const order = useSelector((state) => selectOrderById(state, oId));

  const lineItems = useSelector(selectAllLineItems);
  const orderLineItems = lineItems.filter(
    (lItem) => lItem.order_id === Number(oId)
  );

  const customer = useSelector((state) =>
    selectCustomerById(state, order.customer_id)
  );

  console.log(order);

  const changePage = () => {
    console.log('viewing Invoice');
    const params = { oId, cId: customer.customerId };
    console.log(params);
    navigate({
      pathname: '/orders/invoice',
      search: `?${createSearchParams(params)}`
    });
  };

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          py: 8,
          minHeight: 'calc(100vh - 64px)',
          flexGrow: 1,
          overflow: 'hidden'
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={3}>
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={4}
              item
              xs={12}
            >
              <Grid item>
                <Link
                  underline="hover"
                  onClick={() => navigate(-1)}
                  sx={{ cursor: 'pointer' }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ArrowBack fontSize="small" />
                    <Typography>
                      {location.state ? 'Customer' : 'Orders'}
                    </Typography>
                  </Stack>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Stack spacing={1}>
                          <Typography variant="h4" sx={{ fontWeight: 600 }}>
                            ORDER-{order.order_id}
                          </Typography>
                          <Stack direction="row" alignItems="center">
                            <Typography variant="body1" color="gray">
                              Placed on
                            </Typography>
                            <Today
                              fontSize="small"
                              color="action"
                              sx={{ mx: 1 }}
                            />
                            <Typography variant="body1" color="gray">
                              {` ${convertToDateString(order.order_date)}`}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={changePage}
                      variant="contained"
                      startIcon={<Receipt />}
                      sx={{ textTransform: 'none', fontWeight: 600 }}
                    >
                      View Invoice
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <OrderDetailsCard
                  customer={customer}
                  order={order}
                  orderLineItems={orderLineItems}
                />
              </Grid>
              <Grid item xs={12}>
                <OrderLineItemsCard
                  order={order}
                  orderLineItems={orderLineItems}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default OrderDetailsPage;
