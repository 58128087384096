import React from 'react';
import {
  Container,
  Grid,
  Link,
  Stack,
  Typography,
  Button
} from '@mui/material';
import { Box } from '@mui/system';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CreateCustomerForm from '../components/CreateCustomerForm';

const CreateCustomerPage = () => {
  document.title = 'Create Customer';
  const navigate = useNavigate();

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        py: 8,
        minHeight: '100vh',
        overflow: 'hidden'
      }}
    >
      <Container maxWidth="md">
        <Grid container display="flex" flexDirection="column" spacing={2}>
          <Grid item>
            <Link
              underline="hover"
              component={Button}
              onClick={() => navigate(-1)}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <ArrowBack />
                <Typography sx={{ textTransform: 'none' }}>Back</Typography>
              </Stack>
            </Link>
          </Grid>
          <Grid item>
            <Box>
              <Typography variant="h4" fontWeight={600}>
                Create Customer
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <CreateCustomerForm />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CreateCustomerPage;
