import { ArrowBack, ContentPaste } from '@mui/icons-material';
import { Button, Container, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import React from 'react';
import OrderAnalyticsQuickStats from '../components/analytics/OrderAnalyticsQuickStats';
import { teal } from '@mui/material/colors';

const OrdersAnalyticsPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          backgroundColor: 'background.default',
          minHeight: 'calc(100vh - 64px)',
          py: 8
        }}
      >
        <Container maxWidth="xl">
          <Box
            sx={{
              mb: 4
            }}
          >
            <Button startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
              Back to Home
            </Button>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box
                  p={1.5}
                  sx={{
                    backgroundColor: teal[300],
                    borderRadius: '6px',
                    alignSelf: 'center'
                  }}
                >
                  <ContentPaste />
                </Box>
                <Typography variant="h4" fontWeight={600} color="textPrimary">
                  Order Analytics
                </Typography>
              </Stack>
              <Button
                disableElevation
                variant="contained"
                sx={{ bgcolor: teal[300] }}
              >
                From 01-01-2022 to 08-15-2022
              </Button>
            </Stack>
          </Box>
          <Box>
            <OrderAnalyticsQuickStats />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default OrdersAnalyticsPage;
