import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectCustomerById } from '../redux/customer/customerSlice';
import { selectAllLineItems, selectOrderById } from '../redux/order/orderSlice';

import Invoice from '../components/Invoice';
import { InvoicePDF } from '../components/InvoicePDF';
import PreviewInvoiceDialog from '../components/dialogs/PreviewInvoiceDialog';

const InvoicePage = () => {
  const [viewPDF, setViewPDF] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const oId = searchParams.get('oId');
  const cId = searchParams.get('cId');

  const customer = useSelector((state) => selectCustomerById(state, cId));
  const order = useSelector((state) => selectOrderById(state, oId));
  const lineItems = useSelector(selectAllLineItems);
  const orderLineItems = lineItems.filter(
    (lItem) => lItem.order_id === order.order_id
  );

  useEffect(() => {
    document.title = 'Invoice';
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={3}>
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={4}
              item
              xs={12}
            >
              <Grid item>
                <Link
                  underline="hover"
                  onClick={() => navigate(-1)}
                  sx={{ cursor: 'pointer' }}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ArrowBack fontSize="small" />
                    <Typography>Order</Typography>
                  </Stack>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between">
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item>
                        <Avatar sx={{ height: 50, width: 50 }} />
                      </Grid>
                      <Grid item>
                        <Stack>
                          <Typography variant="h5" sx={{ fontWeight: 600 }}>
                            ORDER-#{order.order_id}
                          </Typography>
                          <Typography variant="body1" color="gray">
                            {customer.billTo1}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button
                          variant="outlined"
                          onClick={() => setViewPDF(true)}
                        >
                          <Typography
                            sx={{ fontWeight: 600, textTransform: 'none' }}
                          >
                            Preview
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item>
                        <PDFDownloadLink
                          document={
                            <InvoicePDF
                              order={order}
                              customer={customer}
                              orderLineItems={orderLineItems}
                            />
                          }
                          fileName={`Invoice_${order.order_id}`}
                          style={{ textDecoration: 'none' }}
                        >
                          <Button
                            variant="contained"
                            sx={{ textTransform: 'none', fontWeight: 600 }}
                          >
                            Download
                          </Button>
                        </PDFDownloadLink>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 3 }} />
              </Grid>
              <Grid item xs={12}>
                <Invoice
                  order={order}
                  customer={customer}
                  orderLineItems={orderLineItems}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <PreviewInvoiceDialog
        order={order}
        customer={customer}
        lineItems={orderLineItems}
        open={viewPDF}
        setViewPDF={setViewPDF}
      />
    </>
  );
};

export default InvoicePage;
