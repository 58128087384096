import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import LogIn from './components/auth/Login';
import DraftOrdersPage from './pages/DraftOrdersPage';
import SavedOrdersPage from './pages/SavedOrdersPage';
import ShippingSchedule from './pages/ShippingSchedule';
import PackedOrdersPage from './pages/PackedOrdersPage';
import ItemInventoryPage from './pages/ItemInventoryPage';
import ShippedOrdersPage from './pages/ShippedOrdersPage';
import AdminDashboardPage from './pages/AdminDashboardPage';
import CreateDeliveryOrderPage from './pages/CreateDeliveryOrderPage';
import ArchivedOrdersPage from './pages/ArchivedOrdersPage';
import { ToastContainer } from 'react-toastify';
import UserAccount from './pages/UserAccount';
import NoMatch from './components/NoMatch';
import FinanceDashboard from './pages/FinanceDashboard';
import LogisticsDashboard from './pages/LogisticsDashboard';
import InvoiceListPage from './pages/InvoiceListPage';
import SettingsDrawer from './components/SettingsDrawer';
import { createTheme } from './theme';
import CreateItemPage from './pages/CreateItemPage';
import CustomersPage from './pages/CustomersPage';
import CustomerDetailsPage from './pages/CustomerDetailsPage';
import OrderDetailsPage from './pages/OrderDetailsPage';
import InvoicePage from './pages/InvoicePage';
import ItemDetailsPage from './pages/ItemDetailsPage';
import { useSettings } from './hooks/useSettings';
import { SnackbarProvider } from 'notistack';
import OrdersAnalyticsPage from './pages/OrdersAnalyticsPage';
import GlobalStyles from './components/GlobalStyles';
import CreateCustomerPage from './pages/CreateCustomerPage';

const App = () => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ToastContainer
          autoClose={8000}
          hideProgressBar
          toastStyle={{ backgroundColor: 'black' }}
        />
        <GlobalStyles />
        <SnackbarProvider dense maxSnack={3}>
          <SettingsDrawer />
          <Routes>
            <Route path="/login" element={<LogIn />} />
            <Route exact path="/" element={<PrivateRoute />}>
              <Route exact path="/home" element={<AdminDashboardPage />} />
              <Route path="/invoices" element={<InvoiceListPage />} />
              <Route exact path="/account" element={<UserAccount />} />
              <Route
                path="/analytics/orders"
                element={<OrdersAnalyticsPage />}
              />
              <Route
                path="/create-order"
                element={<CreateDeliveryOrderPage />}
              />
              <Route path="/create-customer" element={<CreateCustomerPage />} />
              <Route
                path="/orders/draft-orders"
                element={<DraftOrdersPage />}
              />
              <Route
                path="/orders/saved-orders"
                element={<SavedOrdersPage />}
              />
              <Route
                path="/orders/packed-orders"
                element={<PackedOrdersPage />}
              />
              <Route
                path="/orders/shipped-orders"
                element={<ShippedOrdersPage />}
              />
              <Route path="/orders/archived" element={<ArchivedOrdersPage />} />
              <Route exact path="/orders/invoice" element={<InvoicePage />} />
              <Route
                path="/item-inventory/item"
                element={<ItemDetailsPage />}
              />
              <Route
                path="/customers/customer"
                element={<CustomerDetailsPage />}
              />
              <Route exact path="/customers" element={<CustomersPage />} />
              <Route path="/shipping-schedule" element={<ShippingSchedule />} />
              <Route path="/orders/view-order" element={<OrderDetailsPage />} />
              <Route
                path="/product-inventory"
                element={<ItemInventoryPage />}
              />
              <Route path="/create-item" element={<CreateItemPage />} />
              <Route path="/finance" element={<FinanceDashboard />} />
              <Route path="/logistics" element={<LogisticsDashboard />} />
            </Route>
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </SnackbarProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
