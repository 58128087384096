import React, { useEffect } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Skeleton
} from '@mui/material';
import numeral from 'numeral';
import { StatusPill } from './StatusPill';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCustomerOrders,
  selectAllOrders
} from '../redux/order/orderSlice';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import { convertToDateString } from '../utils/convertToDateString';

const CustomerOrdersList = ({ customer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const custOrdersLoading = useSelector((state) => state.orders.loading);
  const custOrders = useSelector(selectAllOrders);

  const changePage = (orderId) => {
    console.log('viewing order from cust orders Page');
    const params = { oId: orderId };
    navigate(
      {
        pathname: '/orders/view-order',
        search: `?${createSearchParams(params)}`
      },
      { state: { prevPath: location.state } }
    );
  };

  useEffect(() => {
    dispatch(fetchCustomerOrders(customer.customerId));
  }, []);

  return (
    <Card>
      <CardHeader title="Recent Orders" />
      <Divider />
      <Table sx={{ minWidth: 600 }}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Payment Status</TableCell>
            <TableCell>Order Status</TableCell>
          </TableRow>
        </TableHead>
        {custOrdersLoading ? (
          <TableBody>
            {Array(10)
              .fill()
              .map((row) => (
                <TableRow key={row}>
                  {Array(5)
                    .fill()
                    .map((cell) => (
                      <TableCell key={cell}>
                        <Skeleton />
                      </TableCell>
                    ))}
                </TableRow>
              ))}
          </TableBody>
        ) : (
          <TableBody>
            {custOrders.map((order) => (
              <TableRow
                key={order.order_id}
                hover
                onClick={() => changePage(order.order_id)}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>#{order.order_id}</TableCell>
                <TableCell>{convertToDateString(order.order_date)}</TableCell>
                <TableCell>{numeral(order.total).format('$0,0.00')}</TableCell>
                <TableCell>
                  <StatusPill
                    color={
                      order.payment_status === 'PAID' ? 'success' : 'error'
                    }
                  >
                    {order.payment_status}
                  </StatusPill>
                </TableCell>
                <TableCell>
                  <StatusPill
                    color={order.order_status === 'SAVED' ? 'success' : 'error'}
                  >
                    {order.order_status}
                  </StatusPill>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </Card>
  );
};

export default CustomerOrdersList;
