import {
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import PendingIcon from "@mui/icons-material/Pending";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ReactApexChart from "react-apexcharts";

export default function LogisticsDashboard() {
  const radialData = {
    series: [76, 67, 61, 90],
    options: {
      chart: {
        height: 390,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 360,
          hollow: {
            margin: 5,
            size: "30%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: true,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: ["#1ab7ea", "#0084ff", "#39539E", "#0077B5"],
      labels: ["On Route", "Errors", "Warnings", "Late"],
      legend: {
        show: true,
        floating: false,
        fontSize: "16px",
        position: "right",
        offsetX: 20,
        offsetY: 60,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },
        formatter: function (seriesName, opts) {
          return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex];
        },
        itemMargin: {
          vertical: 3,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  };

  return (
    <Box mx={4} mt={6}>
      <Typography variant="h4" fontWeight="600">
        Logistics
      </Typography>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            sx={{ mt: 2 }}
            spacing={2}
          >
            <Grid item xs={6} lg={3}>
              <Box
                component={Paper}
                elevation={0}
                variant="outlined"
                py={2}
                pl={2}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <PendingIcon color="success" fontSize="large" />
                  <Typography variant="h4" fontWeight={600}>
                    10
                  </Typography>
                </Stack>
                <Typography mt={2} variant="body1" color="GrayText">
                  Vehicles On Route
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Box
                component={Paper}
                elevation={0}
                variant="outlined"
                py={2}
                pl={2}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <ErrorIcon color="error" fontSize="large" />
                  <Typography variant="h4" fontWeight={600}>
                    2
                  </Typography>
                </Stack>
                <Typography mt={2} variant="body1" color="GrayText">
                  Vehicles with errors
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Box
                component={Paper}
                elevation={0}
                variant="outlined"
                py={2}
                pl={2}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <WarningIcon color="warning" fontSize="large" />
                  <Typography variant="h4" fontWeight={600}>
                    1
                  </Typography>
                </Stack>
                <Typography mt={2} variant="body1" color="GrayText">
                  Vehicles Deviated from Route
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Box
                component={Paper}
                elevation={0}
                variant="outlined"
                py={2}
                pl={2}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <TimelapseIcon color="info" fontSize="large" />
                  <Typography variant="h4" fontWeight={600}>
                    4
                  </Typography>
                </Stack>
                <Typography mt={2} variant="body1" color="GrayText">
                  Late Vehicles
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={12} lg={6}>
              <Box component={Paper} elevation={0} variant="outlined">
                <Typography variant="h6" fontWeight={600} py={2} pl={2}>
                  Total Vehicles
                </Typography>
                <Divider />
                <Box>
                  <ReactApexChart
                    options={radialData.options}
                    series={radialData.series}
                    type="radialBar"
                    height={300}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box component={Paper} elevation={0} variant="outlined">
                <Typography variant="h6" fontWeight={600} py={2} pl={2}>
                  Vehicle Conditions
                </Typography>
                <Divider />
                <Box>Charts go here</Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Box component={Paper} elevation={0} variant="outlined">
                <Typography variant="h6" fontWeight={600} pt={2} pl={2}>
                  On Route Vehicles
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={600}
                  color="GrayText"
                  pl={2}
                  pb={2}
                >
                  Vehicle Monitoring
                </Typography>
                <Divider />
                <Table>
                  <TableRow>
                    <TableCell>
                      <Typography fontWeight={600}>Vehicle</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>Start</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>End</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>Warnings</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight={600}>Status</Typography>
                    </TableCell>
                  </TableRow>
                </Table>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
