import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { editLineItem } from '../redux/order/orderSlice';

const EditLineItemStatusDialog = ({ open, onClose, itemToEdit }) => {
  const [value, setValue] = useState(itemToEdit.status);
  const radioGroupRef = React.useRef(null);

  const handleCancel = () => onClose();
  const handleChange = (e) => setValue(e.target.value);

  const update = () => {
    const status = value;
    const editPayload = {
      description: itemToEdit.description,
      disount: itemToEdit.discount,
      line_id: itemToEdit.line_id,
      order_id: itemToEdit.order_id,
      total: itemToEdit.total,
      price: itemToEdit.price,
      quantity: itemToEdit.quantity,
      status: status
    };
    const savePayload = itemToEdit;
    dispatch(
      editLineItem({
        lid: itemToEdit.line_id,
        editPayload: editPayload,
        savePayload: savePayload
      })
    );
    onClose();
  };

  const dispatch = useDispatch();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`Change status on Item: ${itemToEdit.description}?`}</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup ref={radioGroupRef} onChange={handleChange} value={value}>
            <FormControlLabel
              value="PENDING"
              label="PULLING"
              control={<Radio color="primary" />}
            />
            <FormControlLabel
              value="PACKED"
              label="PACKED"
              control={<Radio color="primary" />}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          style={{ border: '12px' }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={value === itemToEdit.status}
          color="primary"
          disableElevation
          style={{ border: '12px' }}
          onClick={() => update()}
        >
          <Typography>Update</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditLineItemStatusDialog;
