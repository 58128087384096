import App from './App';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { store, persistor } from './redux/store';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { PersistGate } from 'redux-persist/integration/react';
import AuthProvider from './components/auth/AuthContext';

import { StyledEngineProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { SettingsProvider } from './contexts/settingsContext';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import './index.css';

ReactDOM.render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <SettingsProvider>
              <AuthProvider>
                <App />
              </AuthProvider>
            </SettingsProvider>
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </StyledEngineProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
