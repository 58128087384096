import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";

import Conversation from "../img/Big Shoes - Chatting.png";

export default function NoMatch() {
  console.log("on no match");
  return (
    <Container maxWidth="md">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignContent="center"
        columns={1}
        sx={{ mt: 20 }}
      >
        <Grid item>
          <Stack>
            <Box sx={{ alignSelf: "center" }}>
              <Typography variant="h3" fontStyle="italic">
                404
              </Typography>
            </Box>
            <Box sx={{ alignSelf: "center" }}>
              <Typography variant="h4">Page Not Found</Typography>
            </Box>
            <Box sx={{ alignSelf: "center" }}>
              <img src={Conversation} alt="Not Found" />
            </Box>
            <Box sx={{ alignSelf: "center" }}>
              <Typography variant="h6">
                You look lost friend. Go back home and try again.
              </Typography>
            </Box>
            <Box sx={{ alignSelf: "center", mt: 4 }}>
              <Button
                component={Link}
                to={"/home"}
                variant="outlined"
                disableElevation
                sx={{ borderRadius: 2 }}
              >
                <Typography sx={{ textTransform: "none", fontWeight: 600 }}>
                  Home
                </Typography>
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
}
