import React, { useState, useEffect } from 'react';
import {
  Card,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  FormControlLabel,
  InputAdornment,
  TableSortLabel,
  Skeleton,
  TableRow,
  TextField,
  Switch,
  Typography,
  FormControl,
  TablePagination,
  Stack
} from '@mui/material';
import { Box } from '@mui/system';
import { visuallyHidden } from '@mui/utils';
import numeral from 'numeral';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItems, selectAllItems } from '../redux/item/itemSlice';
import { useNavigate, createSearchParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { StatusPill } from './StatusPill';

const headerCells = [
  {
    id: 'item',
    label: 'Name'
  },
  {
    id: 'description',
    label: 'Description'
  },
  {
    id: 'price',
    label: 'Price'
  },
  {
    id: 'quantityOnHand',
    label: 'Quantity on Hand'
  },
  {
    id: 'activeStatus',
    label: 'Active Status'
  }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const SortableTableHead = (props) => {
  const { order, orderBy, onRequestSort, headerCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headerCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const ProductInventoryTable = () => {
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showInactive, setShowInactive] = useState(false);
  const [showInStock, setShowInStock] = useState(true);
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('item');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const itemsLoading = useSelector((state) => state.items.loading);
  const items = useSelector(selectAllItems);

  useEffect(() => {
    dispatch(fetchItems());
  }, [dispatch]);

  const changePage = (item) => {
    console.log('diving into single Item');
    const params = { itemId: item.itemId };
    navigate(
      {
        pathname: '/item-inventory/item',
        search: `?${createSearchParams(params)}`
      },
      { state: item }
    );
  };

  const handleInactiveCheck = () => {
    setShowInactive(!showInactive);
  };

  const handleInStockCheck = () => {
    setShowInStock(!showInStock);
  };

  const filterItems = (items = []) => {
    let visItems;

    if (showInStock && !showInactive) {
      visItems = items.filter(
        (item) => item.quantityOnHand > 0 && item.activeStatus === 'Active'
      );
    } else if (showInactive && !showInStock) {
      visItems = items;
    } else if (showInStock && showInactive) {
      visItems = items.filter((item) => item.quantityOnHand > 0);
    } else {
      visItems = items.filter((item) => item.activeStatus === 'Active');
    }

    const filteredItems = visItems.filter(
      (item) => item.item !== null && item.item.toLowerCase().includes(search)
    );

    return filteredItems;
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Card>
      <TableContainer>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            p: 2
          }}
        >
          <TextField
            sx={{ width: '40%' }}
            variant="outlined"
            value={search}
            type="search"
            placeholder="Search Products"
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="action" />
                </InputAdornment>
              )
            }}
          />
          <Stack direction="column" spacing={1}>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch color="primary" onChange={handleInactiveCheck} />
                }
                label="Display Inactive"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={showInStock}
                    onChange={handleInStockCheck}
                  />
                }
                label="In Stock"
              />
            </FormControl>
          </Stack>
        </Box>

        <Table>
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headerCells={headerCells}
          />
          {itemsLoading ? (
            <TableBody>
              {Array(10)
                .fill()
                .map((row) => (
                  <TableRow key={row}>
                    {Array(5)
                      .fill()
                      .map((cell) => (
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      ))}
                  </TableRow>
                ))}
            </TableBody>
          ) : (
            <TableBody>
              {stableSort(filterItems(items), getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) =>
                  item ? (
                    <TableRow
                      key={item.id}
                      hover
                      onClick={() => changePage(item)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell>
                        <Typography>{item.item}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{item.description}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {numeral(item.price).format('$0,0.00')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{item.quantityOnHand}</Typography>
                      </TableCell>
                      <TableCell>
                        <StatusPill
                          color={
                            item.activeStatus === 'Active' ? 'success' : 'error'
                          }
                        >
                          {item.activeStatus}
                        </StatusPill>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell colSpan="6">No Items</TableCell>
                    </TableRow>
                  )
                )}
            </TableBody>
          )}
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filterItems(items).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Card>
  );
};

export default ProductInventoryTable;
