import {
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Switch,
  InputAdornment,
  TextField,
  Typography,
  Drawer,
  Button,
  Divider,
  Avatar,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function InvoiceListPage() {
  document.title = 'Invoices';

  const [open, setOpen] = useState(true);
  const containerRef = useRef();

  const [value, setValue] = React.useState();

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const drawerContent = (
    <Box>
      <Stack sx={{ pt: 10, mx: 4 }}>
        <TextField
          type="search"
          placeholder="Search By Invoice Number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="inherit" />
              </InputAdornment>
            )
          }}
        />
        <Typography mt={4} mb={1} color="GrayText">
          Issue Date
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={2}>
            <DesktopDatePicker
              sx={{ mt: 1 }}
              label="From"
              inputFormat="MM/dd/yyyy"
              value={value}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
            <DesktopDatePicker
              sx={{ mt: 1 }}
              label="To"
              inputFormat="MM/dd/yyyy"
              value={value}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>

        <Typography mt={3} color="GrayText">
          From customer
        </Typography>
        <Box
          mt={1}
          component={Paper}
          elevation={0}
          sx={{ height: 200, backgroundColor: '#e6eaec' }}
          padding={0}
        >
          <List dense>
            <ListItem disablePadding disableGutters>
              <ListItemButton>
                <ListItemIcon>
                  <Checkbox edge="start" />
                </ListItemIcon>
                <ListItemText primary="Example Inc." />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
        <FormControl sx={{ mt: 2 }}>
          <FormControlLabel control={<Switch />} label="Show Past Due Only" />
        </FormControl>
      </Stack>
    </Box>
  );

  return (
    <div>
      <Grid
        container
        direction="row"
        ref={containerRef}
        sx={{ position: 'relative' }}
      >
        <Grid
          item
          xs={3}
          display={open ? { xs: 'none', md: 'none', lg: 'block' } : 'none'}
        >
          <Drawer
            variant="persistent"
            anchor="left"
            open={open}
            sx={{
              position: 'relative',
              marginLeft: 'auto',
              width: '100%',
              height: 'calc(100vh - 64px)',
              '& .MuiBackdrop-root': {
                display: 'none'
              },
              '& .MuiDrawer-paper': {
                minWidth: '100%',
                position: 'absolute',
                height: 'calc(100vh -64px)',
                zIndex: 100,
                transition: 'none !important'
              }
            }}
          >
            {drawerContent}
          </Drawer>
          <Drawer
            variant="persistent"
            anchor="left"
            open={open}
            sx={{
              display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' },
              position: 'relative',
              marginLeft: 'auto',
              width: '100%',
              height: 'calc(100vh - 64px)',
              '& .MuiBackdrop-root': {
                display: 'none'
              },
              '& .MuiDrawer-paper': {
                display: { xs: 'block', sm: 'block', md: 'block', lg: 'none' },
                minWidth: '100%',
                position: 'absolute',
                height: 'calc(100vh -64px)',
                zIndex: 100,
                transition: 'none !important'
              }
            }}
          >
            {drawerContent}
          </Drawer>
        </Grid>
        <Grid item xs={open ? 9 : 12}>
          <Box sx={{ mx: 2, mt: 10 }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignContent="center"
            >
              <Grid item>
                <Typography variant="h4" fontWeight={600}>
                  Invoices
                </Typography>
              </Grid>
              <Grid item>
                <Stack>
                  <Stack direction="row" spacing={2} mr={4}>
                    <Button
                      variant="outlined"
                      size="large"
                      disableElevation
                      startIcon={<FilterAltIcon color="white" />}
                      onClick={() => setOpen(!open)}
                    >
                      <Typography
                        sx={{ textTransform: 'none' }}
                        fontWeight={600}
                      >
                        Filters
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      disableElevation
                      startIcon={<AddIcon color="white" />}
                    >
                      <Typography
                        sx={{ textTransform: 'none' }}
                        fontWeight={600}
                      >
                        New
                      </Typography>
                    </Button>
                  </Stack>
                  <Box>
                    <FormControl sx={{ mt: 2 }}>
                      <FormControlLabel
                        control={<Switch />}
                        checked
                        label="Show Groups"
                      />
                    </FormControl>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Grid item>
            <Box mt={6} mx={2}>
              <Stack spacing={2}>
                <Box>
                  <Typography variant="h6" color="GrayText" fontWeight={600}>
                    Paid (1)
                  </Typography>
                  <Divider sx={{ mt: 1 }} />
                </Box>
                <Box
                  component={Paper}
                  elevation={1}
                  sx={{
                    '&:hover': {
                      boxShadow: 3,
                      cursor: 'pointer'
                    }
                  }}
                  px={2}
                  py={2}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                          sx={{ backgroundColor: 'green' }}
                          children="EI"
                        />
                        <Stack>
                          <Typography variant="body1" fontWeight={600}>
                            INV-999
                          </Typography>
                          <Typography variant="body1">Example Inc.</Typography>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box>
                      <Typography variant="body1">$999.99</Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1" fontWeight={600}>
                        Issued
                      </Typography>
                      <Typography variant="body1" color="GrayText">
                        01/01/1970
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body1" fontWeight={600}>
                        Due
                      </Typography>
                      <Typography variant="body1" color="GrayText">
                        01/01/1970
                      </Typography>
                    </Box>
                    <Box>
                      <Chip label="Paid" size="medium" color="success" />
                    </Box>
                    <Box>
                      <Button>
                        <ArrowForwardIcon color="action" />
                      </Button>
                    </Box>
                  </Stack>
                </Box>
                <Box>
                  <Typography variant="h6" color="GrayText" fontWeight={600}>
                    Cancelled (0)
                  </Typography>
                  <Divider sx={{ mt: 1 }} />
                </Box>
                <Box>
                  <Typography variant="h6" color="GrayText" fontWeight={600}>
                    Pending (0)
                  </Typography>
                  <Divider sx={{ mt: 1 }} />
                </Box>
                <Box>
                  <Typography variant="h6" color="GrayText" fontWeight={600}>
                    Past Due (0)
                  </Typography>
                  <Divider sx={{ mt: 1 }} />
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default InvoiceListPage;
