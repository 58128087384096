import React from 'react';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const OrdersPageHeader = () => {
  const naviagte = useNavigate();

  const handleNewOrderClick = () => {
    naviagte('/orders/create-order');
  };

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography
            variant="h4"
            component="h4"
            sx={{ fontWeight: '600', mb: 4, ml: 4 }}
          >
            {document.title}
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title="Create New Order" placement="left" arrow>
            <Button
              sx={{ mr: 4 }}
              disableElevation
              variant="contained"
              onClick={handleNewOrderClick}
              startIcon={<AddIcon />}
            >
              <Typography sx={{ textTransform: 'none', fontWeight: 600 }}>
                New
              </Typography>
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

export default OrdersPageHeader;
