import React, { useState } from 'react';
import { Divider, Tabs, Tab } from '@mui/material';
import { Box } from '@mui/system';
import CustomerDetailsTable from './CustomerDetailsTable';
import CustomerOrdersList from './CustomerOrdersList';

const tabs = [
  {
    label: 'Details',
    value: 'details'
  },
  {
    label: 'Orders',
    value: 'orders'
  }
];

const CustomerInfoTabTable = ({ customer, editMode, setEditMode }) => {
  const [currentTab, setCurrentTab] = useState('details');

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider />
      <Box sx={{ mt: 3 }}>
        {currentTab === 'details' && (
          <CustomerDetailsTable
            editMode={editMode}
            setEditMode={setEditMode}
            customer={customer}
          />
        )}
        {currentTab === 'orders' && <CustomerOrdersList customer={customer} />}
      </Box>
    </>
  );
};

export default CustomerInfoTabTable;
