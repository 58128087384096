import React, { useState } from 'react';
import numeral from 'numeral';
import {
  Button,
  Card,
  CardHeader,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { StatusPill } from './StatusPill';
import { Add, Delete, Edit } from '@mui/icons-material';
import AddItemToOrderDialog from './dialogs/AddItemToOrderDialog';
import EditItemQtyDialog from './EditItemQtyDialog';
import EditLineItemStatusDialog from './EditLineItemStatusDialog';
import DeleteOrdLineItemConfDialog from './dialogs/DeleteOrdLineItemConfDialog';

// TO-DO
// Make table utilize bulk actions
// Fetch items on edit mode click

const OrderLineItemsCard = ({ order, orderLineItems }) => {
  const [page, setPage] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [actionCount, setActionCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [itemToEdit, setItemToEdit] = useState();
  const [itemForDelete, setitemForDelete] = useState();
  const [openAddItems, setOpenAddItems] = useState(false);
  const [openEditStatus, setOpenEditStatus] = useState(false);
  const [openEditItemQty, setOpenEditItemQty] = useState(false);
  const [openDelLineItemConf, setOpenDelLineItemConf] = useState(false);

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleAddClick = () => {
    console.log('add clicked');
    setOpenAddItems(true);
  };

  const handleDelItemClick = (item) => {
    console.log('item for del');
    setitemForDelete(item);
    setOpenDelLineItemConf(true);
  };

  const handleDelItemClose = () => {
    setOpenDelLineItemConf(false);
    setitemForDelete();
  };

  const handleClose = () => {
    setOpenAddItems(false);
  };

  const handleItemEditClick = (item) => {
    setItemToEdit(item);
    setOpenEditItemQty(true);
  };

  const handleItemQtyClose = () => {
    setOpenEditItemQty(false);
  };

  const handleItemStatusClick = (item) => {
    setItemToEdit(item);
    setOpenEditStatus(true);
  };

  const handleItemStatusClose = () => {
    setOpenEditStatus(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const HeaderTableCell = ({ primary }) => {
    return <TableCell sx={{ fontWeight: 600 }}>{primary}</TableCell>;
  };

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Typography variant="h6" style={{ fontWeight: 600, my: 6, px: 1 }}>
              Items
            </Typography>
          }
          action={
            !editMode ? (
              <Tooltip
                arrow
                placement="left"
                title={orderLineItems.length ? 'Edit' : 'Add'}
              >
                {orderLineItems.length ? (
                  <Button
                    variant="contained"
                    onClick={() => setEditMode(true)}
                    sx={{ mr: 2, mt: 0.5 }}
                  >
                    <Edit fontSize="small" />
                  </Button>
                ) : (
                  <Button variant="contained" onClick={handleAddClick}>
                    <Add fontSize="small" />
                  </Button>
                )}
              </Tooltip>
            ) : (
              <Stack direction="row" spacing={2} sx={{ pr: 2 }}>
                <Button onClick={handleCancel}>
                  {actionCount > 0 ? 'Done' : 'Cancel'}
                </Button>
                <Tooltip arrow placement="top" title="Add">
                  <Button variant="contained" onClick={handleAddClick}>
                    <Add fontSize="small" />
                  </Button>
                </Tooltip>
              </Stack>
            )
          }
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderTableCell primary="Item" />
                <HeaderTableCell primary="QTY. Ordered" />
                <HeaderTableCell primary="QTY. Received" />
                <HeaderTableCell primary="Price" />
                <HeaderTableCell primary="Status" />
                {editMode && <HeaderTableCell primary="Delete" />}
              </TableRow>
            </TableHead>
            <TableBody>
              {orderLineItems
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell>
                      <Typography variant="body2" color="textPrimary">
                        {item.description}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" color="textPrimary">
                        {item.quantity}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {editMode ? (
                        <StatusPill
                          color="info"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleItemEditClick(item)}
                        >
                          {item.quantity}
                        </StatusPill>
                      ) : (
                        <Typography variant="body2" color="textPrimary">
                          {item.quantity}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" color="textPrimary">
                        {numeral(item.price).format('$0,0.00')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {editMode ? (
                        <StatusPill
                          color={
                            item.status === 'PENDING' ? 'warning' : 'success'
                          }
                          sx={{ cursor: 'pointer' }}
                          onClick={() => handleItemStatusClick(item)}
                        >
                          {item.status}
                        </StatusPill>
                      ) : (
                        <StatusPill
                          color={
                            item.status === 'PENDING' ? 'warning' : 'success'
                          }
                        >
                          {item.status}
                        </StatusPill>
                      )}
                    </TableCell>
                    {editMode && (
                      <TableCell>
                        <IconButton onClick={() => handleDelItemClick(item)}>
                          <Delete color="error" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={orderLineItems.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
      <AddItemToOrderDialog
        open={openAddItems}
        onClose={handleClose}
        order={order}
      />
      <DeleteOrdLineItemConfDialog
        open={openDelLineItemConf}
        onClose={handleDelItemClose}
        itemToDel={itemForDelete}
        orderId={order.order_id}
        actionCount={actionCount}
        setActionCount={setActionCount}
      />
      {itemToEdit && (
        <EditItemQtyDialog
          open={openEditItemQty}
          onClose={handleItemQtyClose}
          itemToEdit={itemToEdit}
          order={order}
        />
      )}
      {itemToEdit && (
        <EditLineItemStatusDialog
          open={openEditStatus}
          onClose={handleItemStatusClose}
          itemToEdit={itemToEdit}
        />
      )}
    </>
  );
};

export default OrderLineItemsCard;
