import React from "react";
import { Button, Typography } from "@mui/material";

export const OrderViewButton = ({ allOrderView, setAllOrderView }) => {
  return (
    <Button
      variant="contained"
      disableElevation
      onClick={() => setAllOrderView(!allOrderView)}
    >
      <Typography
        variant="body1"
        color="white"
        sx={{ textTransform: "none", fontWeight: 600 }}
      >
        {allOrderView ? "All Orders" : "User Orders"}
      </Typography>
    </Button>
  );
};

export default OrderViewButton;
