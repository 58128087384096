import firebase from "firebase/app";
import "firebase/auth";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRE_API,
  authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
  projectId: "emerald-leaf",
  storageBucket: "emerald-leaf.appspot.com",
  messagingSenderId: "231575812979",
  appId: process.env.REACT_APP_FIRE_APP_ID,
  measurementId: "G-W95B9GNHWH",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
export const auth = firebase.auth();
