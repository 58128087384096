import React, { useState } from 'react';
import {
  Badge,
  Box,
  Fade,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  useMediaQuery,
  Drawer,
  Grid,
  Button,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Payment from './payment/Payment';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CustomerInformationCard from './CustomerInformationCard';
import OrderRevisionConfDialog from './dialogs/OrderRevisionConfDialog';

const CartDrawerDesktop = styled(Drawer)({
  flexShrink: 0,
  width: 530,
  '& .MuiDrawer-paper': {
    position: 'relative',
    width: 530
  }
});

const CartDrawerMobile = styled(Drawer)({
  maxWidth: '100%',
  width: 530,
  '& .MuiDrawer-paper': {
    minHeight: 'calc(100% - 64px)',
    maxWidth: '100%',
    width: 530
  }
});

const OrderFormDetail = ({
  open,
  containerRef,
  itemsToAdd,
  customerInfo,
  setItemsToAdd,
  setCustomerInfo,
  onClose,
  ...other
}) => {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const [paymentMethod, setPaymentMethod] = useState('');
  const [orderType, setOrderType] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openRevision, setOpenRevision] = useState(false);

  console.log(itemsToAdd);

  const handleMenuChange = (event) => {
    setPaymentMethod(event.target.value);
    if (event.target.value === 'Card') {
      handleModalOpen();
    }
  };

  const handleOrderMenuChange = (event) => {
    setOrderType(event.target.value);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const calcOrderTotal = function (itemsToAdd) {
    let total = 0;

    // eslint-disable-next-line
    for (const [key, value] of Object.entries(itemsToAdd)) {
      let linePrice = value.quantity * value.price;
      total = total + linePrice;
    }

    formatter.format(total);
    return total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  const handleRemoveItem = (itemId) => {
    const newItems = itemsToAdd.filter((item) => item.itemId !== itemId);
    setItemsToAdd(newItems);
  };

  console.log(customerInfo);

  const content = (
    <>
      <Box sx={{ mt: 4, px: 2 }}>
        <Grid container spacing={3}>
          <Grid item>
            <Typography variant="h5" fontWeight={600}>
              Order Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomerInformationCard
              customerInfo={customerInfo}
              setCustomerInfo={setCustomerInfo}
            />
          </Grid>
          <Grid item>
            <Box>
              <Typography variant="body2" fontWeight={600} sx={{ pt: 1 }}>
                Items ({itemsToAdd.length})
              </Typography>
              <Table>
                <TableBody>
                  {itemsToAdd.length !== 0 &&
                    itemsToAdd.map((item, i) => (
                      <Fade in>
                        <TableRow sx={{ borderBottom: 'none' }}>
                          <TableCell>{item.description}</TableCell>
                          <TableCell>${item.price.toFixed(2)}</TableCell>
                          <TableCell>x</TableCell>
                          <TableCell>{item.quantity}</TableCell>
                          <TableCell>
                            ${(item.quantity * item.price).toFixed(2)}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              variant="text"
                              onClick={() => handleRemoveItem(item.itemId)}
                              size="small"
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <IconButton>
                              <Badge badgeContent={'30%'} color="warning">
                                <LocalOfferIcon color="info" />
                              </Badge>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </Fade>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Box>

      <Box>
        <Stack
          direction="row"
          sx={{ px: 2, pt: 2 }}
          justifyContent="space-between"
        >
          <Typography>Account Balance</Typography>
          <Typography>${customerInfo ? customerInfo.balance : null}</Typography>
        </Stack>
        <Stack direction="row" sx={{ px: 2 }} justifyContent="space-between">
          <Typography>Credits</Typography>
          <Typography>$0.00</Typography>
        </Stack>
        <Stack direction="row" sx={{ px: 2 }} justifyContent="space-between">
          <Typography>Discounts</Typography>
          <Typography>$0.00</Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{ px: 2 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>Order Total</Typography>
          <Typography variant="h6" fontWeight={600}>
            ${calcOrderTotal(itemsToAdd)}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pt: 2, pl: 2, pr: 1, pb: 2 }}
        >
          <FormControl sx={{ minWidth: '100%' }}>
            <InputLabel id="order-type-open-select-label">
              Order Type
            </InputLabel>
            <Select
              fullWidth
              labelId="order-type-open-select-label"
              id="order-type-open-select-label"
              value={orderType}
              onChange={handleOrderMenuChange}
            >
              <MenuItem value="Delivery">Delivery</MenuItem>
              <MenuItem value="Pickup">Pickup</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ pl: 2, pb: 2, pr: 1 }}
        >
          <FormControl sx={{ minWidth: '100%' }}>
            <InputLabel id="order-type-open-select-label">
              Payment Method
            </InputLabel>
            <Select
              fullWidth
              labelId="payment-open-select-label"
              id="payment-open-select-label"
              label="Payment Method"
              open={menuOpen}
              onClose={handleMenuClose}
              onOpen={handleMenuOpen}
              value={paymentMethod}
              onChange={handleMenuChange}
            >
              <MenuItem value={'Accept Payment Later'}>
                Accept Payment Later
              </MenuItem>
              <MenuItem value={'Check'}>Check</MenuItem>
              <MenuItem value={'Cash'}>Cash</MenuItem>
              <MenuItem value={'Card'}>Card</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" justifyContent="space-around" sx={{ p: 2 }}>
          <Button
            variant="contained"
            fullWidth
            disabled={!orderType || !paymentMethod}
            onClick={() => setOpenRevision(true)}
          >
            Review Order
          </Button>
        </Stack>
        <Payment open={modalOpen} onClose={handleModalClose} />
        {customerInfo && (
          <OrderRevisionConfDialog
            open={openRevision}
            setOpen={setOpenRevision}
            customerInfo={customerInfo}
            orderType={orderType}
            paymentMethod={paymentMethod}
            itemsToAdd={itemsToAdd}
          />
        )}
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <CartDrawerDesktop
        anchor="right"
        open={open}
        SlideProps={{ container: containerRef?.current }}
        variant="persistent"
        {...other}
      >
        {content}
      </CartDrawerDesktop>
    );
  }

  return (
    <CartDrawerMobile
      anchor="right"
      ModalProps={{ container: containerRef?.current }}
      onClose={onClose}
      open={open}
      SlideProps={{ container: containerRef?.current }}
      variant="temporary"
      {...other}
    >
      {content}
    </CartDrawerMobile>
  );
};

export default OrderFormDetail;

// var today = new Date();
// var dd = String(today.getDate()).padStart(2, '0');
// var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
// var yyyy = today.getFullYear();

// today = yyyy + '-' + mm + '-' + dd;
