import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { deleteLineItem } from '../../redux/order/orderSlice';

const DeleteOrdLineItemConfDialog = ({
  open,
  onClose,
  itemToDel,
  orderId,
  actionCount,
  setActionCount
}) => {
  const dispatch = useDispatch();

  const handleDeleteLineItem = (orderId, lineId) => {
    dispatch(deleteLineItem({ oid: orderId, lid: lineId }));
    setActionCount(actionCount + 1);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Item</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => handleDeleteLineItem(orderId, itemToDel.line_id)}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrdLineItemConfDialog;
