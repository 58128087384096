import React, { useState, useEffect } from 'react';
import {
  FormControlLabel,
  InputAdornment,
  TableRow,
  TextField,
  Chip,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableCell,
  Switch,
  FormControl,
  TableContainer,
  TablePagination,
  Card,
  CardContent
} from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllItems } from '../redux/item/itemSlice';
import { fetchItems } from '../redux/item/itemSlice';
import SearchIcon from '@mui/icons-material/Search';
import SelectItemToAddDialog from './dialogs/SelectItemToAddDialog';

//TO-DO
// Add empty table state

const headerCells = [
  'Name',
  'Description',
  'Price',
  'Units Available',
  'Status'
];

const AddItemsToOrderTable = ({ itemsToAdd, setItemsToAdd, order, closeItemTable }) => {
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [wholeItem, setWholeItem] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showInStock, setShowInStock] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showInactive, setShowInactive] = useState(false);

  const dispatch = useDispatch();

  const items = useSelector(selectAllItems);

  useEffect(() => {
    dispatch(fetchItems());
  }, [dispatch]);

  const handleInactiveCheck = () => {
    setShowInactive(!showInactive);
  };

  const handleInStockCheck = () => {
    setShowInStock(!showInStock);
  };

  function filterItems(items = []) {
    let visItems;

    if (showInStock && !showInactive) {
      visItems = items.filter(
        (item) => item.quantityOnHand > 0 && item.activeStatus === 'Active'
      );
    } else if (showInactive && !showInStock) {
      visItems = items;
    } else if (showInStock && showInactive) {
      visItems = items.filter((item) => item.quantityOnHand > 0);
    } else {
      visItems = items.filter((item) => item.activeStatus === 'Active');
    }

    const filteredItems = visItems.filter(
      (item) => item.item !== null && item.item.toLowerCase().includes(search)
    );

    return filteredItems;
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filterItems(items).length - page * rowsPerPage);

  const handleRowClick = (item) => {
    console.log('in handle row');
    console.log(item);
    const lItem = {
      description: item.description,
      discount: 0,
      itemId: item.itemId,
      vendorId: item.preferredVendor,
      quantity: null,
      price: Number(item.price),
      status: 'PENDING',
      total: null
    };
    setWholeItem(item);
    setSelectedItem(lItem);
    setOpen(true);
  };

  return (
    <>
      <Card>
        <Box px={2}>
          <Grid
            container
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={8}>
              <TextField
                variant="outlined"
                fullWidth
                value={search}
                label="Search Products"
                onChange={(e) => setSearch(e.target.value.toLowerCase())}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item>
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch color="primary" onChange={handleInactiveCheck} />
                  }
                  label="Display Inactive"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={showInStock}
                      onChange={handleInStockCheck}
                    />
                  }
                  label="In Stock"
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <CardContent sx={{ p: 0 }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headerCells.map((cell) => (
                    <TableCell sx={{ fontWeight: 600 }}>{cell}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filterItems(items)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) =>
                    item ? (
                      <TableRow
                        key={index}
                        hover
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleRowClick(item)}
                      >
                        <TableCell>{item.item}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>${item.price}</TableCell>
                        <TableCell>{item.quantityOnHand}</TableCell>
                        <TableCell>
                          <Chip
                            size="medium"
                            color={
                              item.activeStatus === 'Active'
                                ? 'primary'
                                : 'error'
                            }
                            label={item.activeStatus}
                          />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell colSpan="6">No Items</TableCell>
                      </TableRow>
                    )
                  )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filterItems(items).length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <SelectItemToAddDialog
        open={open}
        wholeItem={wholeItem}
        itemToAdd={selectedItem}
        itemsToAdd={itemsToAdd}
        setItemsToAdd={setItemsToAdd}
        setOpen={setOpen}
        order={order}
        setSearch={setSearch}
        closeItemTable={closeItemTable}
      />
    </>
  );
};

export default AddItemsToOrderTable;
