import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper
} from '@mui/material';
import { Box } from '@mui/system';
import numeral from 'numeral';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addOrder } from '../../redux/order/orderSlice';

import { StatusPill } from '../StatusPill';

const OrderRevisionConfDialog = ({
  open,
  setOpen,
  customerInfo,
  orderType,
  paymentMethod,
  itemsToAdd
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  const order = {
    userId: user.userId,
    orderStatus: null,
    customerId: customerInfo.customerId,
    paymentMethod: paymentMethod,
    orderType: orderType,
    credit: false,
    creditAmount: 0,
    disocunt: 0,
    paymentStatus: 'UNPAID'
  };

  const formatLineItems = (lineItems) => {
    return lineItems.map((val) => {
      const lItem = {
        orderId: null,
        itemId: val.itemId,
        vendorId: val.preferredVendor,
        quantity: val.quantity,
        status: 'PENDING',
        discount: val.discount,
        total: val.quantity * val.price
      };
      return lItem;
    });
  };

  const handleDraft = (order, lineItems) => {
    console.log('clicked here');
    console.log(order);
    console.log(lineItems);
    order.orderStatus = 'DRAFT';
    order.paymentMethod = paymentMethod;
    order.customer = customerInfo.billTo1;
    const formattedItems = formatLineItems(lineItems);
    const data = { order, formattedItems };
    console.log(data);
    dispatch(addOrder(data));
    navigate('/home');
  };

  const handleSave = (order, lineItems) => {
    if (lineItems.length) {
      order.orderStatus = 'SAVED';
      order.paymentMethod = paymentMethod;
      const formattedItems = formatLineItems(lineItems);
      console.log(formattedItems);
      const data = { order, formattedItems };
      console.log(data);
      dispatch(addOrder(data));
      navigate('/home');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  const calcOrderTotal = function (itemsToAdd) {
    let total = 0;

    // eslint-disable-next-line
    for (const [key, value] of Object.entries(itemsToAdd)) {
      let linePrice = value.quantity * value.price;
      total = total + linePrice;
    }

    formatter.format(total);
    return total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Stack direction="row" spacing={2}>
          <Typography variant="h5">Review Order</Typography>
          <StatusPill color={orderType === 'Delivery' ? 'success' : 'warning'}>
            {orderType}
          </StatusPill>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Box>
            <Typography variant="h6" color="textSecondary">
              Customer
            </Typography>
            <Typography>{customerInfo.customer}</Typography>
          </Box>
          {orderType === 'Delivery' && (
            <Box>
              <Typography variant="h6" color="textSecondary">
                Shipping Address
              </Typography>
              <Box>
                <Typography>{customerInfo.shipTo1}</Typography>
                <Typography>{customerInfo.shipTo2}</Typography>
                <Typography>{customerInfo.shipTo3}</Typography>
                <Typography>{customerInfo.shipTo4}</Typography>
                <Typography>{customerInfo.shipTo5}</Typography>
              </Box>
            </Box>
          )}
          <Box>
            <Typography variant="h6" color="textSecondary">
              Items
            </Typography>
            <Paper variant="outlined">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell fontWeight={600}>Description</TableCell>
                    <TableCell fontWeight={600}>Price</TableCell>
                    <TableCell fontWeight={600}>Quantity</TableCell>
                    <TableCell fontWeight={600}>Discount (%)</TableCell>
                    <TableCell fontWeight={600} align="right">
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemsToAdd.length ? (
                    <div>
                      {itemsToAdd.map((item) => (
                        <TableRow>
                          <TableCell>{item.description}</TableCell>
                          <TableCell>
                            {numeral(item.price).format('$0,0.00')}
                          </TableCell>
                          <TableCell>{item.quantity}</TableCell>
                          <TableCell>
                            {numeral(item.discount).format('0%')}
                          </TableCell>
                          <TableCell align="right">
                            {numeral(item.total).format('$0,0.00')}
                          </TableCell>
                        </TableRow>
                      ))}
                    </div>
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={5}>
                        No Order Items
                      </TableCell>
                    </TableRow>
                  )}
                  {itemsToAdd.length && (
                    <>
                      <TableRow>
                        <TableCell colSpan={4} align="right">
                          Item Total
                        </TableCell>
                        <TableCell align="right">
                          {numeral(calcOrderTotal(itemsToAdd)).format(
                            '$0,0.00'
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4} align="right">
                          Account Balance
                        </TableCell>
                        <TableCell align="right">
                          {numeral(customerInfo.balance).format('$0,0.00')}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4} align="right">
                          Credits
                        </TableCell>
                        <TableCell align="right">N/A</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4} align="right">
                          Discounts
                        </TableCell>
                        <TableCell align="right">N/A</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={4} align="right">
                          Order Total
                        </TableCell>
                        <TableCell align="right">
                          {numeral(calcOrderTotal(itemsToAdd)).format(
                            '$0,0.00'
                          )}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Box>
          <Box sx={{ pt: 2 }}>
            <Stack direction="row" spacing={4} justifyContent="center">
              <Button
                size="large"
                variant="contained"
                color="success"
                disabled={!itemsToAdd.length}
                onClick={() => handleSave(order, itemsToAdd)}
              >
                Confirm & Save
              </Button>
              <Button
                size="large"
                variant="contained"
                color="warning"
                onClick={() => handleDraft(order, itemsToAdd)}
              >
                Draft Order
              </Button>
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default OrderRevisionConfDialog;
