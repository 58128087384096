import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './root-reducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import persistStore from 'redux-persist/es/persistStore';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['customers', 'orders', 'user']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer
});

let persistor = persistStore(store);

export { store, persistor };
