import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Container,
  Grid,
  Link,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ArrowBack, Edit } from '@mui/icons-material';
import { StatusPill } from '../components/StatusPill';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleItem, selectAllItems } from '../redux/item/itemSlice';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import ItemDetailsCard from '../components/ItemDetailsCard';

const ItemDetailsPage = () => {
  const [editMode, setEditMode] = useState(false);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const itemId = searchParams.get('itemId');

  const itemsLoading = useSelector((state) => state.items.loading);
  const singleItem = useSelector(selectAllItems);
  const item = singleItem[0];

  useEffect(() => {
    console.log('grabbing single item');
    dispatch(fetchSingleItem(itemId));
    console.log('ran dispatch');
  }, [dispatch, itemId]);

  console.log(item);

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          py: 8,
          minHeight: '100vh',
          flexGrow: 1,
          overflow: 'hidden'
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={3}>
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={4}
              item
              xs={12}
            >
              <Grid item>
                <Link
                  component={RouterLink}
                  underline="hover"
                  to="/product-inventory"
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ArrowBack fontSize="small" />
                    <Typography>Items</Typography>
                  </Stack>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" spacing={2}>
                    <Avatar variant="rounded" sx={{ width: 64, height: 64 }}>
                      <InsertPhotoIcon fontSize="large" />
                    </Avatar>
                    <Stack>
                      <Typography variant="h5" fontWeight={600}>
                        {itemsLoading ? <Skeleton /> : item.description}
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography color="textSecondary" fontWeight={600}>
                          Item:
                        </Typography>
                        <StatusPill>
                          {itemsLoading ? <Skeleton /> : item.item}
                        </StatusPill>
                      </Stack>
                    </Stack>
                  </Stack>
                  {editMode ? (
                    <Button onClick={() => setEditMode(false)}>Cancel</Button>
                  ) : (
                    <Button
                      variant="outlined"
                      startIcon={<Edit />}
                      onClick={() => setEditMode(true)}
                    >
                      <Typography
                        sx={{ textTransform: 'none', fontWeight: 600 }}
                      >
                        Edit
                      </Typography>
                    </Button>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {itemsLoading ? (
                  <Skeleton />
                ) : (
                  <ItemDetailsCard
                    item={item}
                    editMode={editMode}
                    setEditMode={setEditMode}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ItemDetailsPage;
