import React, { useState } from 'react';
import numeral from 'numeral';
import {
  Chip,
  IconButton,
  Skeleton,
  Tooltip,
  Grid,
  Typography,
  Stack,
  Card
} from '@mui/material';
import { Box } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import FaceIcon from '@mui/icons-material/Face';
import ClearIcon from '@mui/icons-material/Clear';
import generateAvatarLetters from '../utils/generateAvatarLetters';
import { Edit } from '@mui/icons-material';
import EditCustomerShippingAddressDialog from './dialogs/EditCustomerShippingAddressDialog';

const billingString = (str1, str2) => {
  const formattedString = str1 + ' ' + str2;
  return formattedString;
};

const CustomerInformationCard = ({ customerInfo, setCustomerInfo }) => {
  const [openShippingInfo, setOpenShippingInfo] = useState(false);

  const handleClose = () => {
    setOpenShippingInfo(false);
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          p: 2,
          borderRadius: '12px',
          backgroundColor: 'tertiary.main',
          color: 'tertiary.contrastText'
        }}
      >
        <Box>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Stack direction="row" spacing={1.5} alignItems="center">
                {customerInfo ? (
                  <Avatar {...generateAvatarLetters(customerInfo.billTo1)} />
                ) : (
                  <Skeleton variant="circular" />
                )}

                <Stack>
                  <Typography
                    noWrap
                    variant="body1"
                    fontWeight={600}
                    color="inherit"
                  >
                    {customerInfo ? (
                      customerInfo.billTo1
                    ) : (
                      <Skeleton width="100%" />
                    )}
                  </Typography>
                  <Typography variant="caption" color="inherit">
                    {customerInfo ? (
                      billingString(customerInfo.billTo2, customerInfo.billTo3)
                    ) : (
                      <Skeleton />
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setCustomerInfo()} size="large">
                <ClearIcon sx={{ color: 'white' }} fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          {customerInfo && (
            <>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-start"
                sx={{ py: 2 }}
              >
                <Tooltip title="Rep" arrow placement="bottom">
                  <Chip
                    icon={<FaceIcon />}
                    variant="contained"
                    color="success"
                    label={customerInfo.rep}
                  />
                </Tooltip>
                <Tooltip title="Balance" arrow>
                  <Chip
                    variant="contained"
                    color={customerInfo.balance <= 0 ? 'success' : 'error'}
                    label={numeral(customerInfo.balance).format('$0,0.00')}
                  />
                </Tooltip>
                <Tooltip title="Terms" arrow>
                  <Chip color="info" label={`${customerInfo.terms}`} />
                </Tooltip>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="column">
                  <Typography variant="caption" color="inherit">
                    Shipping
                  </Typography>
                  <Typography>{customerInfo.shipTo1}</Typography>
                  <Typography>{customerInfo.shipTo2}</Typography>
                  <Typography>{customerInfo.shipTo3}</Typography>
                  <Typography>{customerInfo.shipTo4}</Typography>
                  <Typography>{customerInfo.shipTo5}</Typography>
                </Stack>
                <Box>
                  <Tooltip title="Edit" placement="left" arrow>
                    <IconButton onClick={() => setOpenShippingInfo(true)}>
                      <Edit sx={{ color: 'white' }} fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Stack>
            </>
          )}
        </Box>
      </Card>
      {customerInfo && (
        <EditCustomerShippingAddressDialog
          open={openShippingInfo}
          onClose={handleClose}
          customerInfo={customerInfo}
        />
      )}
    </>
  );
};

export default CustomerInformationCard;
