import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OrdersTable from '../components/OrdersTable';
import OrdersPageHeader from '../components/OrdersPageHeader';
import { fetchShippedOrders, selectAllOrders } from '../redux/order/orderSlice';
import { Box } from '@mui/material';

const ShippedOrdersPage = () => {
  document.title = 'Shipped Orders';

  const dispatch = useDispatch();
  const orders = useSelector(selectAllOrders);
  const lineItems = useSelector((state) => state.orders.lineItems.entities);

  useEffect(() => {
    dispatch(fetchShippedOrders());
  }, [dispatch]);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        py: 8,
        flexGrow: 1
      }}
    >
      <OrdersPageHeader />
      <OrdersTable key={orders.id} orders={orders} lineItems={lineItems} />
    </Box>
  );
};

export default ShippedOrdersPage;
