import React, { useState } from 'react';
import {
  Avatar,
  Button,
  Container,
  Grid,
  Link,
  Stack,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { ArrowBack, Edit } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import CustomerInfoTabTable from '../components/CustomerInfoTabTable';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCustomerById } from '../redux/customer/customerSlice';
import { StatusPill } from '../components/StatusPill';
import generateAvatarLetters from '../utils/generateAvatarLetters';

const CustomerDetailsPage = () => {
  const [editMode, setEditMode] = useState(false);
  const [searchParams] = useSearchParams();
  const custId = searchParams.get('custId');
  const customer = useSelector((state) => selectCustomerById(state, custId));

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          py: 8,
          minHeight: '100vh',
          flexGrow: 1,
          overflow: 'hidden'
        }}
      >
        <Container maxWidth="md">
          <Grid container spacing={3}>
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={4}
              item
              xs={12}
            >
              <Grid item>
                <Link component={RouterLink} underline="hover" to="/customers">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <ArrowBack fontSize="small" />
                    <Typography>Customers</Typography>
                  </Stack>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      {...generateAvatarLetters(customer?.billTo1)}
                      sx={{ width: 64, height: 64 }}
                    />
                    <Stack>
                      <Typography
                        variant="h5"
                        fontWeight={600}
                        color="textPrimary"
                      >
                        {customer.customer}
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography color="textSecondary" fontWeight={600}>
                          Customer Id:
                        </Typography>
                        <StatusPill color="primary">
                          {customer.customerId}
                        </StatusPill>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Button
                    variant="outlined"
                    startIcon={<Edit />}
                    onClick={() => setEditMode(true)}
                  >
                    <Typography sx={{ textTransform: 'none', fontWeight: 600 }}>
                      Edit
                    </Typography>
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <CustomerInfoTabTable
                  editMode={editMode}
                  setEditMode={setEditMode}
                  customer={customer}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CustomerDetailsPage;
