import React from 'react';
import {
  Button,
  Card,
  FormHelperText,
  TextField,
  Typography,
  Divider,
  Stack,
  Grid,
  CardContent
} from '@mui/material';
import { Box } from '@mui/system';
import { ErrorSharp } from '@mui/icons-material';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { createCustomer } from '../redux/customer/customerSlice';

const CreateCustomerForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Card>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            minHeight: '100%'
          }}
        >
          <Formik
            initialValues={{
              accountNo: '',
              active: '',
              altPhone: '',
              balance: '',
              balanceTotal: '',
              billTo1: '',
              billTo2: '',
              billTo3: '',
              billTo4: '',
              billTo5: '',
              company: '',
              creditLimit: '',
              customer: '',
              customerType: 'EL',
              fax: '',
              firstName: '',
              jobTitle: '',
              lastName: '',
              mainEmail: '',
              mainPhone: '',
              prefix: '',
              primaryContact: '',
              rep: '',
              resaleNum: '',
              salesTaxCode: '',
              secondaryContact: '',
              shipTo1: '',
              shipTo2: '',
              shipTo3: '',
              shipTo4: '',
              shipTo5: '',
              taxItem: '',
              terms: ''
            }}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                console.log('dispatching');
                console.log(values);
                //dispatch(createCustomer({ values }));
                navigate('/customers');
              } catch (error) {
                console.log(error.message);
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              setFieldValue,
              values
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography variant="h5" fontWeight={600}>
                        Basic Details
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.accountNo && ErrorSharp.accountNo
                        )}
                        fullWidth
                        helperText={touched.accountNo && ErrorSharp.accountNo}
                        label="Account No."
                        margin="dense"
                        name="accountNo"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="accountNo"
                        value={values.accountNo}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.active && ErrorSharp.active)}
                        fullWidth
                        helperText={touched.active && ErrorSharp.active}
                        label="Active"
                        margin="dense"
                        name="active"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="active"
                        value={values.active}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.company && ErrorSharp.company)}
                        fullWidth
                        helperText={touched.company && ErrorSharp.company}
                        label="Company"
                        margin="dense"
                        name="company"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="company"
                        value={values.company}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.customer && ErrorSharp.customer)}
                        fullWidth
                        helperText={touched.customer && ErrorSharp.customer}
                        label="Customer"
                        margin="dense"
                        name="customer"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="customer"
                        value={values.customer}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.customerType && ErrorSharp.customerType
                        )}
                        fullWidth
                        helperText={
                          touched.customerType && ErrorSharp.customerType
                        }
                        label="Customer Type"
                        margin="dense"
                        name="customerType"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="customerType"
                        value={values.customerType}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.rep && ErrorSharp.rep)}
                        fullWidth
                        helperText={touched.rep && ErrorSharp.rep}
                        label="Rep"
                        margin="dense"
                        name="rep"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="rep"
                        value={values.rep}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.terms && ErrorSharp.terms)}
                        fullWidth
                        helperText={touched.terms && ErrorSharp.terms}
                        label="Terms"
                        margin="dense"
                        name="terms"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="terms"
                        value={values.terms}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5" fontWeight={600}>
                        Contact
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.primaryContact && ErrorSharp.primaryContact
                        )}
                        fullWidth
                        helperText={
                          touched.primaryContact && ErrorSharp.primaryContact
                        }
                        label="Primary Contact"
                        margin="dense"
                        name="primaryContact"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="primaryContact"
                        value={values.primaryContact}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.secondaryContact &&
                            ErrorSharp.secondaryContact
                        )}
                        fullWidth
                        helperText={
                          touched.secondaryContact &&
                          ErrorSharp.secondaryContact
                        }
                        label="Secondary Contact"
                        margin="dense"
                        name="secondaryContact"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="secondaryContact"
                        value={values.secondaryContact}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.prefix && ErrorSharp.prefix)}
                        fullWidth
                        helperText={touched.prefix && ErrorSharp.prefix}
                        label="Prefix"
                        margin="dense"
                        name="prefix"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="prefix"
                        value={values.prefix}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.firstName && ErrorSharp.firstName
                        )}
                        fullWidth
                        helperText={touched.firstName && ErrorSharp.firstName}
                        label="First Name"
                        margin="dense"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="firstName"
                        value={values.firstName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.lastName && ErrorSharp.lastName)}
                        fullWidth
                        helperText={touched.lastName && ErrorSharp.lastName}
                        label="Last Name"
                        margin="dense"
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="lastName"
                        value={values.lastName}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.jobTitle && ErrorSharp.jobTitle)}
                        fullWidth
                        helperText={touched.jobTitle && ErrorSharp.jobTitle}
                        label="Job Title"
                        margin="dense"
                        name="jobTitle"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="jobTitle"
                        value={values.jobTitle}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.mainPhone && ErrorSharp.mainPhone
                        )}
                        fullWidth
                        helperText={touched.mainPhone && ErrorSharp.mainPhone}
                        label="Main Phone"
                        margin="dense"
                        name="mainPhone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="mainPhone"
                        value={values.mainPhone}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.altPhone && ErrorSharp.altPhone)}
                        fullWidth
                        helperText={touched.altPhone && ErrorSharp.altPhone}
                        label="Alt Phone"
                        margin="dense"
                        name="altPhone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="altPhone"
                        value={values.altPhone}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.mainEmail && ErrorSharp.mainEmail
                        )}
                        fullWidth
                        helperText={touched.mainEmail && ErrorSharp.mainEmail}
                        label="Main Email"
                        margin="dense"
                        name="mainEmail"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="mainEmail"
                        value={values.mainEmail}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.fax && ErrorSharp.fax)}
                        fullWidth
                        helperText={touched.fax && ErrorSharp.fax}
                        label="Fax"
                        margin="dense"
                        name="fax"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="fax"
                        value={values.fax}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5" fontWeight={600}>
                        Addresses
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        fontWeight={600}
                        color="textSecondary"
                      >
                        Billing
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.billTo1 && ErrorSharp.billTo1)}
                        fullWidth
                        helperText={touched.billTo1 && ErrorSharp.billTo1}
                        label="Bill To 1"
                        margin="dense"
                        name="billTo1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="billTo1"
                        value={values.billTo1}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.billTo2 && ErrorSharp.billTo2)}
                        fullWidth
                        helperText={touched.billTo2 && ErrorSharp.billTo2}
                        label="Bill To 2"
                        margin="dense"
                        name="billTo2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="billTo2"
                        value={values.billTo2}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.billTo3 && ErrorSharp.billTo3)}
                        fullWidth
                        helperText={touched.billTo3 && ErrorSharp.billTo3}
                        label="Bill To 3"
                        margin="dense"
                        name="billTo3"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="billTo3"
                        value={values.billTo3}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.billTo4 && ErrorSharp.billTo4)}
                        fullWidth
                        helperText={touched.billTo4 && ErrorSharp.billTo4}
                        label="Bill To 4"
                        margin="dense"
                        name="billTo4"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="billTo4"
                        value={values.billTo4}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.billTo5 && ErrorSharp.billTo5)}
                        fullWidth
                        helperText={touched.billTo5 && ErrorSharp.billTo5}
                        label="Bill To 5"
                        margin="dense"
                        name="billTo5"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="billTo5"
                        value={values.billTo5}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        fontWeight={600}
                        color="textSecondary"
                      >
                        Shipping
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.shipTo1 && ErrorSharp.shipTo1)}
                        fullWidth
                        helperText={touched.shipTo1 && ErrorSharp.shipTo1}
                        label="Ship To 1"
                        margin="dense"
                        name="shipTo1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="shipTo1"
                        value={values.shipTo1}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.shipTo2 && ErrorSharp.shipTo2)}
                        fullWidth
                        helperText={touched.shipTo2 && ErrorSharp.shipTo2}
                        label="Ship To 2"
                        margin="dense"
                        name="shipTo2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="shipTo2"
                        value={values.shipTo2}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.shipTo3 && ErrorSharp.shipTo3)}
                        fullWidth
                        helperText={touched.shipTo3 && ErrorSharp.shipTo3}
                        label="Ship To 3"
                        margin="dense"
                        name="shipTo3"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="shipTo3"
                        value={values.shipTo3}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.shipTo4 && ErrorSharp.shipTo4)}
                        fullWidth
                        helperText={touched.shipTo4 && ErrorSharp.shipTo4}
                        label="Ship To 4"
                        margin="dense"
                        name="shipTo4"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="shipTo4"
                        value={values.shipTo4}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.shipTo5 && ErrorSharp.shipTo5)}
                        fullWidth
                        helperText={touched.shipTo5 && ErrorSharp.shipTo5}
                        label="Ship To 5"
                        margin="dense"
                        name="shipTo5"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="shipTo5"
                        value={values.shipTo5}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5" fontWeight={600}>
                        Financials
                      </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.creditLimit && ErrorSharp.creditLimit
                        )}
                        fullWidth
                        helperText={
                          touched.creditLimit && ErrorSharp.creditLimit
                        }
                        label="Credit Limit"
                        margin="dense"
                        name="creditLimit"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="creditLimit"
                        value={values.creditLimit}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.resaleNum && ErrorSharp.resaleNum
                        )}
                        fullWidth
                        helperText={touched.resaleNum && ErrorSharp.resaleNum}
                        label="Resale Num."
                        margin="dense"
                        name="resaleNum"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="resaleNum"
                        value={values.resaleNum}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(
                          touched.salesTaxCode && ErrorSharp.salesTaxCode
                        )}
                        fullWidth
                        helperText={
                          touched.salesTaxCode && ErrorSharp.salesTaxCode
                        }
                        label="Sales Tax Code"
                        margin="dense"
                        name="salesTaxCode"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="salesTaxCode"
                        value={values.salesTaxCode}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        error={Boolean(touched.taxItem && ErrorSharp.taxItem)}
                        fullWidth
                        helperText={touched.taxItem && ErrorSharp.taxItem}
                        label="Tax Item"
                        margin="dense"
                        name="taxItem"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="taxItem"
                        value={values.taxItem}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" justifyContent="flex-end">
                        <Stack
                          direction="row"
                          spacing={3}
                          justifyContent="flex-end"
                        >
                          <Button variant="text" type="reset">
                            Reset
                          </Button>
                          <Button
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            Save
                          </Button>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            )}
          </Formik>
        </Box>
      </Card>
    </>
  );
};

export default CreateCustomerForm;
