import React, { useRef } from 'react';
import {
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import numeral from 'numeral';
import { Box } from '@mui/system';
import { convertToDateString } from '../utils/convertToDateString';
import Logo from '../img/TheEmeraldLeafLogoHigh-removebg-preview (1).png';

const Invoice = ({ order, customer, orderLineItems }) => {
  const componentRef = useRef();

  return (
    <Paper variant="outlined" sx={{ borderRadius: 2 }}>
      <Grid container direction="column">
        <Grid item xs={12}>
          <div ref={componentRef}>
            <Box p={3} component={Paper} elevation={0}>
              <Grid container direction="column" spacing={6}>
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <img
                        src={Logo}
                        alt="Emerald leaf Logo"
                        loading="lazy"
                        style={{ height: '10vh', width: '10wv' }}
                      />
                      <Typography variant="h6" color="textPrimary">
                        www.theemeraldleaf.com
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Stack>
                        <Typography variant="subtitle1">
                          {order.order_status}
                        </Typography>
                        <Typography variant="subtitle1">
                          Order # {order.order_id}
                        </Typography>
                        <Typography variant="subtitle1">
                          Rep: {order.email}
                        </Typography>
                        <Typography variant="subtitle1">
                          Order Date: {convertToDateString(order.order_date)}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Stack>
                        <Typography variant="body1" color="textPrimary">
                          Billing
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          8773 West Iliff Lane
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Lakewood, CO 80227
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Stack>
                        <Typography variant="body1" color="textPrimary">
                          Greenhouse
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          10195 Wadsworth Blvd.
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Broomfield, CO 80021
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Stack>
                        <Typography variant="body1" color="textPrimary">
                          Phone: 303.278.7590
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          Fax: 303.986.3688
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                          Email: info@theemeraldleaf.com
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <Stack>
                        <Typography variant="body1" color="textPrimary">
                          Billed To
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {customer.billTo1}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {customer.billTo2}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {customer.billTo3}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {customer.billTo4}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {customer.billTo5}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Stack>
                        <Typography variant="body1" color="textPrimary">
                          Shipped to
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {customer.shipTo1}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {customer.shipTo2}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {customer.shipTo3}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {customer.shipTo4}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {customer.shipTo5}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item>
                      <Stack>
                        <Typography variant="body1" color="textPrimary">
                          Customer Contact
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Email: {customer.mainEmail}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Phone: {customer.mainPhone}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Fax: {customer.fax}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Contact: {customer.primaryContact}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell>QTY. Ordered</TableCell>
                          <TableCell>QTY. Received</TableCell>
                          <TableCell>Unit Price</TableCell>
                          <TableCell>Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderLineItems.map((item, idx) => (
                          <TableRow key={idx}>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>{item.quantity}</TableCell>
                            <TableCell>{item.quantity}</TableCell>
                            <TableCell>
                              {numeral(item.price).format('$0,0.00')}
                            </TableCell>
                            <TableCell>
                              {numeral(item.total).format('$0,0.00')}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan="2" align="right">
                            Items:
                          </TableCell>
                          <TableCell colSpan="4">
                            {orderLineItems.length}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan="4" align="right">
                            Subtotal:
                          </TableCell>
                          <TableCell>
                            {numeral(order.total).format('$0,0.00')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan="4" align="right">
                            Account Balance:
                          </TableCell>
                          <TableCell>
                            {numeral(customer.balance).format('$0,0.00')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan="4" align="right">
                            Invoice Total:
                          </TableCell>
                          <TableCell>
                            {numeral(order.total).format('$0,0.00')}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Invoice;
