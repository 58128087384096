import React, { useEffect } from 'react';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDraftOrders, selectAllOrders } from '../redux/order/orderSlice';
import OrdersPageHeader from '../components/OrdersPageHeader';
import OrdersTable from '../components/OrdersTable';

const DraftOrdersPage = () => {
  const dispatch = useDispatch();
  const orders = useSelector(selectAllOrders);
  const lineItems = useSelector((state) => state.orders.lineItems.entities);

  useEffect(() => {
    document.title = 'Draft Orders';
    dispatch(fetchDraftOrders());
  }, [dispatch]);

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          py: 8,
          minHeight: '100vh',
          flexGrow: 1,
          overflow: 'hidden'
        }}
      >
        <OrdersPageHeader />
        {orders && <OrdersTable orders={orders} lineItems={lineItems} />}
      </Box>
    </>
  );
};

export default React.memo(DraftOrdersPage);
