import { createSlice } from "@reduxjs/toolkit";

let eventGuid = 0;
let todayStr = new Date().toISOString().replace(/T.*$/, ""); // YYYY-MM-DD of today

export function createEventId() {
  return String(eventGuid++);
}

export const initialState = [
  {
    id: createEventId(),
    title: "All-day Shipment",
    driver: "zach",
    start: todayStr,
    resourceEditable: true,
  },
  {
    id: createEventId(),
    title: "Aspen Run",
    start: todayStr + "T12:00:00",
    resourceEditable: true,
  },
];

const shippingSlice = createSlice({
  name: "shipping",
  initialState,
  reducers: {
    eventAdded: {
      reducer(state, action) {
        state.push(action.payload);
      },
    },
  },
});

export const { eventAdded } = shippingSlice.actions;

export default shippingSlice.reducer;
