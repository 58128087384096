export const chargeCard = (amount, card) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    createTransactionRequest: {
      merchantAuthentication: {
        name: process.env.REACT_APP_AUTH_API_ID,
        transactionKey: process.env.REACT_APP_AUTH_TRANS_KEY,
      },
      refId: "123456",
      transactionRequest: {
        transactionType: "authCaptureTransaction",
        amount: amount,
        payment: {
          creditCard: {
            cardNumber: card.number,
            expirationDate: card.exp,
            cardCode: card.code,
          },
        },
      },
    },
  });

  console.log("charging card");
  console.log(card)
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };
  return fetch(process.env.REACT_APP_AUTH_URI, requestOptions)
      .then((response) => response.json())
      .then((result) => (result))
      .catch((error) => (error));
};
