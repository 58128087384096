import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography, Button, Box, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { fetchItems } from '../redux/item/itemSlice.js';
import OrderFormDetail from '../components/OrderFormDetail';
import AddItemsToOrderTable from '../components/AddItemsToOrderTable';
import CustomerSelectionAutoComplete from '../components/CustomerSelectionAutoComplete';
import { Filter } from '@mui/icons-material';

const OrderPageInner = styled('div', {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  flexGrow: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  zIndex: 1,
  [theme.breakpoints.up('lg')]: {
    marginRight: -530
  },
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    [theme.breakpoints.up('lg')]: {
      marginRight: 0
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const CreateOrderPage = () => {
  document.title = 'Create Order';

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [customerInfo, setCustomerInfo] = useState(null);
  const [itemsToAdd, setItemsToAdd] = useState([]);
  const [openSide, setOpenSide] = useState(mdUp);

  const rootRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchItems());
  }, [dispatch]);

  const handleToggleSide = () => {
    setOpenSide((prevState) => !prevState);
  };

  const handleCloseSide = () => {
    setOpenSide(false);
  };

  return (
    <>
      <Box
        component="main"
        ref={rootRef}
        sx={{
          backgroundColor: 'background.default',
          height: 'calc(100vh - 64px)',
          display: 'flex',
          flexGrow: 1,
          overflowX: 'hidden'
        }}
      >
        <OrderPageInner open={openSide}>
          <Box sx={{ mb: 3 }}>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item>
                <Typography variant="h4" color="textPrimary">
                  Create Order
                </Typography>
              </Grid>
              <Grid item sx={{ m: -1 }}>
                <Button
                  endIcon={<Filter fontSize="small" />}
                  onClick={handleToggleSide}
                  sx={{ m: 1 }}
                  variant="outlined"
                >
                  Cart
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ mt: 8 }}>
                  <Grid container justifyContent="center">
                    {!customerInfo && (
                      <Grid item xs={8}>
                        <CustomerSelectionAutoComplete
                          setCustomerInfo={setCustomerInfo}
                          setOpenSide={setOpenSide}
                          openSide={openSide}
                        />
                      </Grid>
                    )}
                    {customerInfo && (
                      <Grid item xs={12}>
                        <AddItemsToOrderTable
                          itemsToAdd={itemsToAdd}
                          setItemsToAdd={setItemsToAdd}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </OrderPageInner>
        <OrderFormDetail
          open={openSide}
          onClose={handleCloseSide}
          containerRef={rootRef}
          customerInfo={customerInfo}
          itemsToAdd={itemsToAdd}
          setItemsToAdd={setItemsToAdd}
          setCustomerInfo={setCustomerInfo}
        />
      </Box>
    </>
  );
};

export default CreateOrderPage;
