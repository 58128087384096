import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: {
    textAlign: "center",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const MessageModal = ({ open, onClose, message, type, onCloseMessage }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="title"
      aria-describedby="description"
    >
      <div style={modalStyle} className={classes.paper}>
        <Typography variant="h4">{`Transaction ${type}`}</Typography>
        {message.map((msg) => (
          <Typography variant="subtitle1">{msg}</Typography>
        ))}
        <Button
          style={{ marginLeft: 10 }}
          variant="contained"
          color="secondary"
          onClick={onClose}
        >
          Close
        </Button>
        {type === "Error" ? <Button
          style={{ marginLeft: 10 }}
          variant="contained"
          color="primary"
          onClick={onCloseMessage}
        >
          Try Again
        </Button> : ""}
      </div>
    </Modal>
  );
};

export default MessageModal;
