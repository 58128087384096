import PropTypes from 'prop-types';
import { Box, Button, Card, Chip, Typography } from '@mui/material';
import BannerImage from '../img/banner-illustration.png';

export const NewFeaturesBanner = (props) => {
  const { onDismiss, ...other } = props;

  return (
    <Card
      sx={{
        alignItems: 'center',
        backgroundColor: 'tertiary.main',
        color: 'primary.contrastText',
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row'
        },
        p: 4
      }}
      {...other}
    >
      <Box
        sx={{
          mr: 4,
          width: 200,
          height: 200,
          '& img': {
            height: 200,
            width: 'auto'
          }
        }}
      >
        <img alt="" src={BannerImage} />
      </Box>
      <div>
        <div>
          <Chip color="success" label="New" />
        </div>
        <Typography color="inherit" sx={{ mt: 2 }} variant="h4">
          Welcome to The Emerald System!
        </Typography>
        <Typography color="inherit" sx={{ mt: 1 }} variant="subtitle2">
          This is your dashboard. Use the side menu to navigate and explore all
          the new features like Order Creation, Inventory Management, and many
          more. This page also serves as a landing page for quick access to all
          your Emerald features!
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Button color="success" onClick={onDismiss} variant="contained">
            Dismiss Banner
          </Button>
        </Box>
      </div>
    </Card>
  );
};

NewFeaturesBanner.propTypes = {
  onDismiss: PropTypes.func
};
