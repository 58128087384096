import { Container, Typography } from "@mui/material";
import React from "react";

export default function FinanceDashboard() {
  return (
    <Container maxWidth="xl" sx={{ mt: 6 }}>
      <Typography variant="h4" fontWeight="600">
        Finance
      </Typography>
    </Container>
  );
}
