import React, { useEffect } from 'react';
import {
  Button,
  Card,
  Divider,
  FormHelperText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  editItem,
  deleteItem,
  fetchSingleItem,
  selectAllItems
} from '../redux/item/itemSlice';
import { Delete } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';

const TitleCell = ({ primary }) => {
  return (
    <TableCell>
      <Typography fontWeight={600} color="textSecondary">
        {primary}
      </Typography>
    </TableCell>
  );
};

const ItemDetailsCard = ({ item, editMode, setEditMode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeleteItem = (itemId) => {
    try {
      console.log('dispatching delete function');
      dispatch(deleteItem({ itemId }));
      navigate('/product-inventory');
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          account: item.account || '',
          accumumulatedDepreciation: item.accumumulatedDepreciation || '',
          activeStatus: item.activeStatus || '',
          assetAccount: item.assetAccount || '',
          cogsAccount: item.cogsAccount || '',
          cost: item.cost || '',
          cs: item.cs || '',
          description: item.description || '',
          discountAmount: item.discountAmount || '',
          imagePath: item.imagePath || '',
          item: item.item || '',
          mpn: item.mpn || '',
          preferredVendor: item.preferredVendor || '',
          price: item.price || '',
          purchaseDescription: item.purchaseDescription || '',
          quantityOnHand: item.quantityOnHand || '',
          reorderPtMin: item.reorderPtMin || '',
          retailPrice: item.retailPrice || '',
          salesTaxCode: item.salesTaxCode || '',
          taxAgency: item.taxAgency || '',
          type: item.type || ''
        }}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            console.log('dispatching');
            console.log(item);
            console.log(values);
            dispatch(editItem({ itemId: item.itemId, editPayload: values }));
            setEditMode(false);
          } catch (error) {
            console.log(error.message);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          values
        }) => (
          <>
            <Card>
              <Box sx={{ p: 2 }}>
                <Typography variant="h6" fontWeight={600}>
                  General Details
                </Typography>
              </Box>
              <Divider />
              <form noValidate onSubmit={handleSubmit}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TitleCell primary="Account" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(touched.account && errors.account)}
                            fullWidth
                            helperText={touched.account && errors.account}
                            label="Account"
                            margin="dense"
                            name="account"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="account"
                            value={values.account}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.account}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Accumulated Depreciation" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(
                              touched.accumumulatedDepreciation &&
                                errors.accumumulatedDepreciation
                            )}
                            fullWidth
                            helperText={
                              touched.accumumulatedDepreciation &&
                              errors.accumumulatedDepreciation
                            }
                            label="accumumulatedDepreciation"
                            margin="dense"
                            name="accumumulatedDepreciation"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="accumumulatedDepreciation"
                            value={values.accumumulatedDepreciation}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>
                            {item.accumumulatedDepreciation}
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Active Status" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(
                              touched.activeStatus && errors.activeStatus
                            )}
                            fullWidth
                            helperText={
                              touched.activeStatus && errors.activeStatus
                            }
                            label="Status"
                            margin="dense"
                            name="activeStatus"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="activeStatus"
                            value={values.activeStatus}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.activeStatus}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Asset Account" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(
                              touched.assetAccount && errors.assetAccount
                            )}
                            fullWidth
                            helperText={
                              touched.assetAccount && errors.assetAccount
                            }
                            label="Asset Account"
                            margin="dense"
                            name="assetAccount"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="assetAccount"
                            value={values.assetAccount}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.assetAccount}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Cogs Account" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(
                              touched.cogsAccount && errors.cogsAccount
                            )}
                            fullWidth
                            helperText={
                              touched.cogsAccount && errors.cogsAccount
                            }
                            label="Cogs Account"
                            margin="dense"
                            name="cogsAccount"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="cogsAccount"
                            value={values.cogsAccount}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.cogsAccount}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Cost" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(touched.cost && errors.cost)}
                            fullWidth
                            helperText={touched.cost && errors.cost}
                            label="Cost"
                            margin="dense"
                            name="cost"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="cost"
                            value={values.cost}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.cost}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Cs" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(touched.cs && errors.cs)}
                            fullWidth
                            helperText={touched.cs && errors.cs}
                            label="cs"
                            margin="dense"
                            name="cs"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="cs"
                            value={values.cs}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.cs}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Description" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(
                              touched.description && errors.description
                            )}
                            fullWidth
                            helperText={
                              touched.description && errors.description
                            }
                            label="Description"
                            margin="dense"
                            name="description"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="description"
                            value={values.description}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.description}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Discount Amount" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(
                              touched.discountAmount && errors.discountAmount
                            )}
                            fullWidth
                            helperText={
                              touched.discountAmount && errors.discountAmount
                            }
                            label="Discount Amount"
                            margin="dense"
                            name="discountAmount"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="discountAmount"
                            value={values.discountAmount}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.discountAmount}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Image" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            disabled
                            error={Boolean(
                              touched.imagePath && errors.imagePath
                            )}
                            fullWidth
                            helperText={touched.imagePath && errors.imagePath}
                            label="Image Path"
                            margin="dense"
                            name="imagePath"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="imagePath"
                            value={values.imagePath}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.imagePath}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Item" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(touched.item && errors.item)}
                            fullWidth
                            helperText={touched.item && errors.item}
                            label="Item"
                            margin="dense"
                            name="item"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="item"
                            value={values.item}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.item}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="mpn" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(touched.mpn && errors.mpn)}
                            fullWidth
                            helperText={touched.mpn && errors.mpn}
                            label="mpn"
                            margin="dense"
                            name="mpn"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="mpn"
                            value={values.mpn}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.mpn}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Preferred Vendor" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(
                              touched.preferredVendor && errors.preferredVendor
                            )}
                            fullWidth
                            helperText={
                              touched.preferredVendor && errors.preferredVendor
                            }
                            label="Preferred Vendor"
                            margin="dense"
                            name="preferredVendor"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="preferredVendor"
                            value={values.preferredVendor}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.preferredVendor}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Price" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(touched.price && errors.price)}
                            fullWidth
                            helperText={touched.price && errors.price}
                            label="Price"
                            margin="dense"
                            name="price"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="price"
                            value={values.price}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.price}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Purchase Description" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(
                              touched.purchaseDescription &&
                                errors.purchaseDescription
                            )}
                            fullWidth
                            helperText={
                              touched.purchaseDescription &&
                              errors.purchaseDescription
                            }
                            label="Purchase Description"
                            margin="dense"
                            name="purchaseDescription"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="purchaseDescription"
                            value={values.purchaseDescription}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.purchaseDescription}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Quantity on Hand" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(
                              touched.quantityOnHand && errors.quantityOnHand
                            )}
                            fullWidth
                            helperText={
                              touched.quantityOnHand && errors.quantityOnHand
                            }
                            label="Quantity on Hand"
                            margin="dense"
                            name="quantityOnHand"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="quantityOnHand"
                            value={values.quantityOnHand}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.quantityOnHand}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Reorder Point" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(
                              touched.reorderPtMin && errors.reorderPtMin
                            )}
                            fullWidth
                            helperText={
                              touched.reorderPtMin && errors.reorderPtMin
                            }
                            label="Reorder Point"
                            margin="dense"
                            name="reorderPtMin"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="reorderPtMin"
                            value={values.reorderPtMin}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.reorderPtMin}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Retail Price" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(
                              touched.retailPrice && errors.retailPrice
                            )}
                            fullWidth
                            helperText={
                              touched.retailPrice && errors.retailPrice
                            }
                            label="Retail Price"
                            margin="dense"
                            name="retailPrice"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="retailPrice"
                            value={values.retailPrice}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.retailPrice}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Sales Tax Code" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(
                              touched.salesTaxCode && errors.salesTaxCode
                            )}
                            fullWidth
                            helperText={
                              touched.salesTaxCode && errors.salesTaxCode
                            }
                            label="Sales Tax Code"
                            margin="dense"
                            name="salesTaxCode"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="salesTaxCode"
                            value={values.salesTaxCode}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.salesTaxCode}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Tax Agency" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(
                              touched.taxAgency && errors.taxAgency
                            )}
                            fullWidth
                            helperText={touched.taxAgency && errors.taxAgency}
                            label="Tax Agency"
                            margin="dense"
                            name="taxAgency"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="taxAgency"
                            value={values.taxAgency}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.taxAgency}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TitleCell primary="Type" />
                      <TableCell>
                        {editMode ? (
                          <TextField
                            error={Boolean(touched.type && errors.type)}
                            fullWidth
                            helperText={touched.type && errors.type}
                            label="Type"
                            margin="dense"
                            name="type"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="type"
                            value={values.type}
                            variant="outlined"
                          />
                        ) : (
                          <Typography>{item.type}</Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
                {editMode && (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ p: 2 }}
                  >
                    <Button
                      startIcon={<Delete color="error" />}
                      onClick={() => handleDeleteItem(item.itemId)}
                    >
                      <Typography color="error">Delete</Typography>
                    </Button>
                    <Box>
                      <Button type="reset" sx={{ mr: 2 }}>
                        Reset
                      </Button>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        Save
                      </Button>
                    </Box>
                  </Stack>
                )}
              </form>
            </Card>
          </>
        )}
      </Formik>
    </>
  );
};

export default ItemDetailsCard;
