import React, { useState } from 'react';
import {
  Button,
  DialogActions,
  IconButton,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Stack
} from '@mui/material';
import { useDispatch } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import { addItemToOrder } from '../../redux/order/orderSlice';

const SelectItemToAdd = ({
  closeItemTable,
  itemToAdd,
  wholeItem,
  setItemsToAdd,
  itemsToAdd,
  open,
  setOpen,
  order,
  setSearch
}) => {
  const [quantity, setQuantity] = useState('');

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    setQuantity('');
  };

  const handleAddItem = () => {
    itemToAdd.quantity = quantity;
    itemToAdd.total = quantity * itemToAdd.price;
    const itemsToAddNew = itemsToAdd.concat(itemToAdd);
    setItemsToAdd(itemsToAddNew);
    setOpen(false);
    setSearch('');
    setQuantity('');
  };
  console.log('item to add');
  console.log(itemToAdd);

  const addItemToExistingOrder = (order) => {
    console.log('order in additem, to existing order');
    console.log(order);
    console.log('itemToAdd');
    console.log(itemToAdd);

    const itemToStore = {
      description: itemToAdd.description,
      discount: 0,
      line_id: null,
      order_id: order.order_id,
      price: Number(itemToAdd.price),
      quantity: Number(quantity),
      status: 'PENDING',
      total: itemToAdd.price * quantity
    };

    const formattedItem = formatLineItems(order, [itemToAdd]);
    console.log('formattedItem');
    console.log(formattedItem);
    console.log('item to store');
    const data = { order, formattedItem, itemToStore };
    dispatch(addItemToOrder(data));
    handleClose();
    closeItemTable();
  };

  const formatLineItems = (order, lineItems) =>
    lineItems.map((val) => {
      const linePrice = parseFloat(val.price) * quantity;
      console.log(linePrice);
      console.log('result of turinary');
      console.log(val);
      const lItem = {
        description: val.description,
        discount: 0,
        orderId: order.order_id,
        itemId: val.itemId,
        vendorId: val.preferredVendor,
        quantity: Number(quantity),
        price: Number(val.price),
        status: 'PENDING',
        total: quantity * val.price
      };
      return lItem;
    });

  return (
    <>
      {itemToAdd && (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
          <DialogTitle>
            <Stack direction="row" spacing={2}>
              <Typography>Add Item:</Typography>
              <Typography>{itemToAdd.description}</Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              display="flex"
              flexDirection="column"
              alignItems="center"
              spacing={2}
              sx={{ py: 2 }}
            >
              {wholeItem.activeStatus === 'Not-active' && (
                <Grid item xs={12}>
                  <Typography color="error" align="center">
                    Warning! This item is marked as "Not-active". Please verify
                    quantity on hand before selling!
                  </Typography>
                </Grid>
              )}
              {wholeItem.quantityOnHand < quantity && (
                <Grid item xs={12}>
                  <Typography color="error" align="center">
                    Selected quantity is greater than quantity on hand. Please
                    select a valid quantity.
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  fullWidth
                  variant="outlined"
                  label="Select Quantity"
                  value={quantity}
                  onChange={(e) => {
                    let input = e.target.value;
                    if (
                      !input ||
                      (input[input.length - 1].match('[0-9]') &&
                        input[0].match('[1-9]'))
                    )
                      setQuantity(input.toString());
                  }}
                  type="text"
                />
              </Grid>
              <Grid item>
                <Typography gutterBottom>
                  Inventory Available: {wholeItem.quantityOnHand}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={handleClose} color="inherit">
              Cancel
            </Button>
            <Button
              onClick={
                order ? () => addItemToExistingOrder(order) : handleAddItem
              }
              type="submit"
              color="primary"
              variant="contained"
              disabled={quantity > wholeItem.quantityOnHand || quantity === ''}
            >
              <Typography sx={{ textTransform: 'none' }}>Add Item</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default SelectItemToAdd;

// <Grid container direction="row" justifyContent="center">
//               <Grid item>Add Item: {itemToAdd.description} </Grid>
//               {wholeItem.activeStatus === 'Not-active' ||
//               wholeItem.quantityOnHand < quantity ? (
//                 <Grid item>
//                   <Tooltip
//                     title={
//                       itemToAdd.activeStatus === 'Not-active'
//                         ? `Warning! This item is marked as "Not-active". Please verify quantity on hand.`
//                         : `Selected quantity is greater than quantity on hand. Please select a valid quantity.`
//                     }
//                   >
//                     <IconButton size="large">
//                       <InfoIcon color="error" />
//                     </IconButton>
//                   </Tooltip>
//                 </Grid>
//               ) : (
//                 ''
//               )}
//             </Grid>
