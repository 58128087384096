import React, { useEffect, useState } from 'react';
import {
  Button,
  DialogActions,
  IconButton,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography
} from '@mui/material';
import { useDispatch } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import { editLineItem } from '../redux/order/orderSlice.js';

const EditItemQtyDialog = ({ itemToEdit, open, onClose }) => {
  const [quantity, setQuantity] = useState(itemToEdit.quantity);

  const dispatch = useDispatch();

  useEffect(() => {
    setQuantity(itemToEdit.quantity);
  }, [itemToEdit]);

  const handleClose = () => {
    onClose();
  };

  const updateLineQuantity = () => {
    const linePrice = itemToEdit.price * quantity;
    const editPayload = {
      description: itemToEdit.description,
      disount: itemToEdit.discount,
      line_id: itemToEdit.line_id,
      order_id: itemToEdit.order_id,
      total: linePrice,
      price: itemToEdit.price,
      quantity: Number(quantity),
      status: itemToEdit.status
    };

    const savePayload = itemToEdit;
    dispatch(
      editLineItem({
        lid: itemToEdit.line_id,
        editPayload: editPayload,
        savePayload: savePayload
      })
    );
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Grid item>
              Change Quantity for Item: {itemToEdit.description}?
            </Grid>
            {itemToEdit.activeStatus === 'Not-active' ||
            itemToEdit.quantityOnHand < quantity ? (
              <Grid item>
                <Tooltip
                  title={
                    itemToEdit.activeStatus === 'Not-active'
                      ? `Warning! This item is marked as "Not-active". Please verify quantity on hand.`
                      : `Selected quantity is greater than quantity on hand. Please select a valid quantity.`
                  }
                >
                  <IconButton size="large">
                    <InfoIcon color="error" />
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <TextField
                autoFocus
                variant="outlined"
                label="Select Quantity"
                value={quantity}
                onChange={(e) => {
                  let input = e.target.value;
                  if (
                    !input ||
                    (input[input.length - 1].match('[0-9]') &&
                      input[0].match('[1-9]'))
                  )
                    setQuantity(input);
                }}
                type="text"
              />
            </Grid>
            <Grid item>
              <Typography>Inventory Available: assign qty</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            <Typography>Cancel</Typography>
          </Button>
          <Button
            onClick={() => updateLineQuantity()}
            type="submit"
            color="primary"
            disableElevation
            variant="contained"
            disabled={
              quantity === itemToEdit.quantity ||
              quantity > 1000 ||
              quantity === '' ||
              quantity === 0
            }
          >
            <Typography>Update</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditItemQtyDialog;
