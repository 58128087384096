import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';

// Api calls for the Order Sate slice
//
//
// Fetches Draft Orders and returns json data
export const fetchDraftOrders = createAsyncThunk(
  'orders/fetchDraftOrders',
  async () => {
    const data = await fetch(process.env.REACT_APP_API + '/order/draft').then(
      (res) => res.json()
    );
    const lineItems = data.map(({ line_items, ...rest }) => line_items).flat();
    const orders = data.map(({ line_items, ...rest }) => rest);
    console.log(orders);
    console.log(lineItems);
    return { lineItems, orders };
  }
);

// Fetches Saved orders and returns json data
export const fetchSavedOrders = createAsyncThunk(
  'orders/fetchSavedOrders',
  async () => {
    const data = await fetch(process.env.REACT_APP_API + '/order/saved').then(
      (res) => res.json()
    );
    const lineItems = data.map(({ line_items }) => line_items).flat();
    const orders = data.map(({ line_items, ...rest }) => rest);
    return { lineItems, orders };
  }
);

// Fetches Packed orders and returns json data
export const fetchPackedOrders = createAsyncThunk(
  'orders/fetchPackedOrders',
  async () => {
    const data = await fetch(process.env.REACT_APP_API + '/order/packed').then(
      (res) => res.json()
    );
    const lineItems = data.map(({ line_items, ...rest }) => line_items).flat();
    const orders = data.map(({ line_items, ...rest }) => rest);
    return { lineItems, orders };
  }
);

// Fetches Shipped orders and returns json data
export const fetchShippedOrders = createAsyncThunk(
  'orders/fetchShippedOrders',
  async () => {
    const data = await fetch(process.env.REACT_APP_API + '/order/shipped').then(
      (res) => res.json()
    );
    const lineItems = data.map(({ line_items }) => line_items).flat();
    const orders = data.map(({ line_items, ...rest }) => rest);
    return { lineItems, orders };
  }
);

// Fetches Archived orders and returns json data
export const fetchArchivedOrders = createAsyncThunk(
  'orders/fetchArchivedOrders',
  async () => {
    const data = await fetch(
      process.env.REACT_APP_API + '/order/archived'
    ).then((res) => res.json());
    const lineItems = data.map(({ line_items }) => line_items).flat();
    const orders = data.map(({ line_items, ...rest }) => rest);
    return { lineItems, orders };
  }
);

export const fetchCustomerOrders = createAsyncThunk(
  'orders/fetchCustomerOrders',
  async (custId) => {
    console.log(custId);
    const data = await fetch(
      process.env.REACT_APP_API + '/order/customer?cid=' + custId
    ).then((response) => response.json());
    console.log(data);
    const lineItems = data.map(({ line_items }) => line_items).flat();
    const orders = data.map(({ line_items, ...rest }) => rest);
    console.log(lineItems);
    console.log(orders);
    return { lineItems, orders };
  }
);

// This fucntion deletes the requested order from the db
// uses orderId and returns orderId for redux state
export const deleteOrder = createAsyncThunk(
  'orders/deleteOrder',
  async (orderId) => {
    await fetch(process.env.REACT_APP_API + '/order?oid=' + orderId, {
      method: 'DELETE'
    });
    return orderId;
  }
);

// Used to edit the order status of a single order
//
// Takes orderId and a body which is status
// Returns both orderId and status for redux state
export const editOrder = createAsyncThunk(
  'orders/editOrder',
  async ({ order_id, order_status }) => {
    console.log(order_id);
    console.log(order_status);
    const body = {
      orderStatus: order_status
    };
    await fetch(process.env.REACT_APP_API + '/order?oid=' + order_id, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
    console.log(order_status);
    return { order_id, order_status };
  }
);

// Edits the line item specific properties
//
// Currently call is working, redux needs different state shape to function correctly
export const editLineItem = createAsyncThunk(
  'orders/editLineItem',
  async ({ lid, editPayload }) => {
    await fetch(process.env.REACT_APP_API + '/line?lid=' + lid, {
      method: 'PUT',
      body: JSON.stringify(editPayload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return { lid, editPayload };
  }
);

export const deleteLineItem = createAsyncThunk(
  '/orders/deleteLineItem',
  async ({ oid, lid }) => {
    await fetch(process.env.REACT_APP_API + '/line?lid=' + lid, {
      method: 'DELETE'
    });
    return { oid, lid };
  }
);

export const addItemToOrder = createAsyncThunk(
  'orders/addLineItem',
  async ({ order, formattedItem, itemToStore }) => {
    await fetch(process.env.REACT_APP_API + '/line', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formattedItem)
    })
      .then((response) => response.json())
      .then((res) => {
        itemToStore.line_id = res[0].lineId;
        console.log(res[0].lineId);
        console.log(itemToStore);
      });
    return { order, formattedItem, itemToStore };
  }
);

export const addOrder = createAsyncThunk(
  'orders/addOrder',
  async ({ order, formattedItems }) => {
    await fetch(process.env.REACT_APP_API + '/order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(order)
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        const itemsWOrderId = formattedItems.map((i) => {
          i.orderId = res.orderId;
          return i;
        });
        console.log('itemsWOrderi');
        console.log(itemsWOrderId);
        //TODO probably move this somewhere else
        fetch(process.env.REACT_APP_API + '/line', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(itemsWOrderId)
        });
      });
  }
);

// entity adapter for Order data
// Sets orders into normalized state
// Ids{} entities{}
const orderAdapter = createEntityAdapter({
  selectId: (order) => order.order_id,
  sortComparer: (a, b) =>
    a.order_id.toString().localeCompare(b.order_id.toString())
});

const lineItemAdapter = createEntityAdapter({
  selectId: (lineItem) => lineItem.line_id
});

// Slice for Orders data
// Error and success handling
// action logic for view mutation
//
// This uses immer, which allows for direct state mutation
const orderSlice = createSlice({
  name: 'orders',
  initialState: orderAdapter.getInitialState({
    loading: false,
    error: null,
    lineItems: lineItemAdapter.getInitialState()
  }),
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchDraftOrders.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchDraftOrders.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        orderAdapter.setAll(state, payload.orders);
        lineItemAdapter.setAll(state.lineItems, payload.lineItems);
      })
      .addCase(fetchDraftOrders.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(fetchSavedOrders.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchSavedOrders.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        orderAdapter.setAll(state, payload.orders);
        lineItemAdapter.setAll(state.lineItems, payload.lineItems);
      })
      .addCase(fetchSavedOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPackedOrders.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchPackedOrders.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        orderAdapter.setAll(state, payload.orders);
        lineItemAdapter.setAll(state.lineItems, payload.lineItems);
      })
      .addCase(fetchPackedOrders.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(fetchShippedOrders.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchShippedOrders.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        orderAdapter.setAll(state, payload.orders);
        lineItemAdapter.setAll(state.lineItems, payload.lineItems);
      })
      .addCase(fetchShippedOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchArchivedOrders.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchArchivedOrders.fulfilled, (state, { payload }) => {
        state.loading = false;
        orderAdapter.setAll(state, payload.orders);
        lineItemAdapter.setAll(state.lineItems, payload.lineItems);
      })
      .addCase(fetchArchivedOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteOrder.fulfilled, (state, { payload: orderId }) => {
        state.loading = false;
        orderAdapter.removeOne(state, orderId);
      })
      .addCase(editOrder.pending, (state, action) => {
        state.loading = 'true';
        state.error = null;
      })
      .addCase(editOrder.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        orderAdapter.updateOne(state, {
          id: payload.order_id,
          changes: payload.order_status
        });
      })
      .addCase(editLineItem.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editLineItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        lineItemAdapter.updateOne(state.lineItems, {
          id: payload.lid,
          changes: payload.editPayload
        });
      })
      .addCase(deleteLineItem.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteLineItem.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        lineItemAdapter.removeOne(state.lineItems, payload.lid);
      })
      .addCase(addItemToOrder.fulfilled, (state, { payload }) => {
        lineItemAdapter.addOne(state.lineItems, payload.itemToStore);
      })
      .addCase(fetchCustomerOrders.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCustomerOrders.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = null;
        orderAdapter.setAll(state, payload.orders);
        lineItemAdapter.setAll(state.lineItems, payload.lineItems);
      });
  }
});

// entity adapter selectors used to grab the state from the slice
export const {
  selectAll: selectAllOrders,
  selectById: selectOrderById,
  selectIds: selectOrderIds
} = orderAdapter.getSelectors((state) => state.orders);

export const { selectAll: selectAllLineItems } = lineItemAdapter.getSelectors(
  (state) => state.orders.lineItems
);

export default orderSlice.reducer;

// import OrderActionTypes from "./order.types";
// import { toast } from "react-toastify";

// export const fetchDraftOrdersStart = () => ({
//   type: OrderActionTypes.FETCH_DRAFT_ORDERS_START,
// });

// export const fetchDraftOrdersSuccess = (draftOrdersMap) => ({
//   type: OrderActionTypes.FETCH_DRAFT_ORDERS_SUCCESS,
//   payload: draftOrdersMap,
// });

// export const fetchDraftOrdersFailure = (errorMessage) => ({
//   type: OrderActionTypes.FETCH_DRAFT_ORDERS_FAILURE,
//   payload: errorMessage,
// });

// export const fetchDraftOrdersStartAsync = () => {
//   return (dispatch) => {
//     dispatch(fetchDraftOrdersStart());
//     fetch(process.env.REACT_APP_API + "/order/draft")
//       .then((response) => response.json())
//       .then((res) => {
//         dispatch(fetchDraftOrdersSuccess(res));
//       })
//       .catch((error) => dispatch(fetchDraftOrdersFailure(error.message)));
//   };
// };

// export const fetchSavedOrdersStart = () => ({
//   type: OrderActionTypes.FETCH_SAVED_ORDERS_START,
// });

// export const fetchSavedOrdersSuccess = (savedOrdersMap) => ({
//   type: OrderActionTypes.FETCH_SAVED_ORDERS_SUCCESS,
//   payload: savedOrdersMap,
// });

// export const fetchSavedOrdersFailure = (errorMessage) => ({
//   type: OrderActionTypes.FETCH_SAVED_ORDERS_FAILURE,
//   payload: errorMessage,
// });

// export const fetchSavedOrdersStartAsync = () => {
//   return (dispatch) => {
//     dispatch(fetchSavedOrdersStart());
//     fetch(process.env.REACT_APP_API + "/order/saved")
//       .then((response) => response.json())
//       .then((res) => {
//         dispatch(fetchSavedOrdersSuccess(res));
//       })
//       .catch((error) => dispatch(fetchSavedOrdersFailure(error.message)));
//   };
// };

// export const delOrder = (oid) => ({
//   type: OrderActionTypes.DEL_ORDER,
//   payload: oid,
// });

// export const delOrderAsync = (oid) => {
//   return (dispatch) => {
//     fetch(process.env.REACT_APP_API + "/order?oid=" + oid, {
//       method: "DELETE",
//     })
//       .then((res) => {
//         console.log("in res of delOrder");
//         dispatch(delOrder(oid));
//         console.log("Order Deleted");
//         toast.success(`Success! Order: ${oid} Deleted!`);
//       })
//       .catch((error) => console.log(error));
//   };
// };

// export const addOrder = (orderWLineItems) => ({
//   type: OrderActionTypes.ADD_ORDER,
//   payload: orderWLineItems,
// });

// export const addItemToOrder = (order, item) => ({
//   type: OrderActionTypes.ADD_ITEM_TO_ORDER,
//   payload: {
//     item: item,
//     order: order,
//   },
// });

// export const addItemToOrderAsync = (data) => {
//   const { order, formattedItem, itemToStore } = data;
//   console.log("in addItemToOrderAsync");
//   console.log(data);
//   return async (dispatch) => {
//     fetch(process.env.REACT_APP_API + "/line", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(formattedItem),
//     })
//       .then((response) => response.json())
//       .then((res) => {
//         itemToStore.line_id = res[0].lineId;
//         console.log(res[0].lineId);
//         console.log(itemToStore);
//         dispatch(addItemToOrder(order, itemToStore));
//         toast.success(
//           `Success! Added: ${itemToStore.quantity}, ${itemToStore.description} To Order ${order.order_id}.`
//         );
//       })
//       .catch((error) => toast.warn(`Error while adding item. ${error}`));
//   };
// };

// export const addOrderAsync = (data) => {
//   const { order, formattedItems } = data;
//   console.log("in addOrderAsync");
//   console.log(order);
//   console.log(formattedItems);
//   return (dispatch) => {
//     fetch(process.env.REACT_APP_API + "/order", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(order),
//     })
//       .then((response) => response.json())
//       .then((res) => {
//         console.log(res);
//         const itemsWOrderId = formattedItems.map((i) => {
//           i.orderId = res.orderId;
//           return i;
//         });
//         console.log("itemsWOrderi");
//         console.log(itemsWOrderId);
//         toast.success(`Order Successfully Created! Sent to ${order.status}.`);
//         //TODO probably move this somewhere else
//         fetch(process.env.REACT_APP_API + "/line", {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(itemsWOrderId),
//         }).catch((error) => toast.error(`Error in creating Order. ${error}`));
//       })
//       .catch((error) => toast.error(`Error in creating Order. ${error}`));
//   };
// };

// export const editOrder = (oid, status) => ({
//   type: OrderActionTypes.EDIT_ORDER,
//   payload: {
//     oid: oid,
//     status: status,
//   },
// });

// export const editOrderAsync = (oid, status) => {
//   const body = {
//     status: status,
//   };
//   console.log(body);
//   return (dispatch) => {
//     fetch(process.env.REACT_APP_API + "/order?oid=" + oid, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(body),
//     })
//       .then((res) => {
//         console.log("in editOrderAsync res");
//         console.log(res);
//         dispatch(editOrder(oid, status));

//         toast.success(`Success! Order: ${oid} has been sent to ${status}`, {
//           style: { backgroundColor: "black" },
//         });
//       })
//       .catch((error) => console.log("Error in editOrderAsync: " + error));
//   };
// };

// export const editLineItem = (lid, item) => ({
//   type: OrderActionTypes.EDIT_LINE_ITEM,
//   payload: {
//     oid: item.order_id,
//     lid: lid,
//     item: item,
//   },
// });

// export const editLineItemAsync = (lid, editPayload, savePayload) => {
//   console.log(editPayload);
//   return (dispatch) => {
//     fetch(process.env.REACT_APP_API + "/line?lid=" + lid, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(editPayload),
//     })
//       .then((res) => {
//         console.log("in edit line item async");
//         console.log(savePayload);
//         dispatch(editLineItem(lid, savePayload));
//         toast.success(
//           `Success! Item: ${savePayload.description} Updated Successfully`
//         );
//       })
//       .catch((error) => console.log("Error in editLineItemAsync: " + error));
//   };
// };

// export const delLineItem = (oid, lid) => ({
//   type: OrderActionTypes.DELETE_LINE_ITEM,
//   payload: { oid, lid },
// });

// export const delLineAsync = (oid, lid) => {
//   return (dispatch) => {
//     fetch(process.env.REACT_APP_API + "/line?lid=" + lid, {
//       method: "DELETE",
//     })
//       .then((res) => {
//         console.log("in res of delLineItem");
//         dispatch(delLineItem(oid, lid));
//         toast.success(`Success! Item Removed from Order ${oid}.`);
//       })
//       .catch((error) => console.log(error));
//   };
// };

// export const fetchOrderByIdStart = () => ({
//   type: OrderActionTypes.FETCH_ORDER_BY_ID_START,
// });

// export const fetchOrderByIdSuccess = (orderMap) => ({
//   type: OrderActionTypes.FETCH_ORDER_BY_ID_SUCCESS,
//   payload: orderMap,
// });

// export const fetchOrderByIdFailure = (errorMessage) => ({
//   type: OrderActionTypes.FETCH_SAVED_ORDERS_FAILURE,
//   payload: errorMessage,
// });

// export const fetchOrdersByIdStartAsync = (selectedOrderId) => {
//   return (dispatch) => {
//     dispatch(fetchOrderByIdStart());
//     console.log("in order fetch");
//     fetch(process.env.REACT_APP_API + `/order/?oid=${selectedOrderId}`)
//       .then((response) => response.json())
//       .then((result) => {
//         dispatch(fetchOrderByIdSuccess(result));
//         console.log(result);
//       })
//       .catch((error) => dispatch(fetchOrderByIdFailure(error.message)));
//   };
// };

// export const fetchPackedOrdersStart = () => ({
//   type: OrderActionTypes.FETCH_PACKED_ORDERS_START,
// });

// export const fetchPackedOrdersSuccess = (draftOrdersMap) => ({
//   type: OrderActionTypes.FETCH_PACKED_ORDERS_SUCCESS,
//   payload: draftOrdersMap,
// });

// export const fetchPackedOrdersFailure = (errorMessage) => ({
//   type: OrderActionTypes.FETCH_PACKED_ORDERS_FAILURE,
//   payload: errorMessage,
// });

// export const fetchPackedOrdersStartAsync = () => {
//   return (dispatch) => {
//     dispatch(fetchPackedOrdersStart());
//     fetch(process.env.REACT_APP_API + "/order/packed")
//       .then((response) => response.json())
//       .then((res) => {
//         dispatch(fetchPackedOrdersSuccess(res));
//       })
//       .catch((error) => dispatch(fetchPackedOrdersFailure(error.message)));
//   };
// };

// export const fetchShippedOrdersStart = () => ({
//   type: OrderActionTypes.FETCH_PACKED_ORDERS_START,
// });

// export const fetchShippedOrdersSuccess = (draftOrdersMap) => ({
//   type: OrderActionTypes.FETCH_PACKED_ORDERS_SUCCESS,
//   payload: draftOrdersMap,
// });

// export const fetchShippedOrdersFailure = (errorMessage) => ({
//   type: OrderActionTypes.FETCH_PACKED_ORDERS_FAILURE,
//   payload: errorMessage,
// });

// export const fetchShippedOrdersStartAsync = () => {
//   return (dispatch) => {
//     dispatch(fetchShippedOrdersStart());
//     fetch(process.env.REACT_APP_API + "/order/shipped")
//       .then((response) => response.json())
//       .then((res) => {
//         dispatch(fetchShippedOrdersSuccess(res));
//       })
//       .catch((error) => dispatch(fetchShippedOrdersFailure(error.message)));
//   };
// };

// export const fetchArchivedOrdersStart = () => ({
//   type: OrderActionTypes.FETCH_ARCHIVED_ORDERS_START,
// });

// export const fetchArchivedOrdersSuccess = (draftOrdersMap) => ({
//   type: OrderActionTypes.FETCH_ARCHIVED_ORDERS_SUCCESS,
//   payload: draftOrdersMap,
// });

// export const fetchArchivedOrdersFailure = (errorMessage) => ({
//   type: OrderActionTypes.FETCH_ARCHIVED_ORDERS_FAILURE,
//   payload: errorMessage,
// });

// export const fetchArchivedOrdersStartAsync = () => {
//   return (dispatch) => {
//     dispatch(fetchArchivedOrdersStart());
//     fetch(process.env.REACT_APP_API + "/order/archived")
//       .then((response) => response.json())
//       .then((res) => {
//         dispatch(fetchArchivedOrdersSuccess(res));
//       })
//       .catch((error) => dispatch(fetchArchivedOrdersFailure(error.message)));
//   };
// };
