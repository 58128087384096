import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './auth/AuthContext';
import DashboardLayout from './DashboardLayout';

const PrivateRoute = () => {
  const { currentUser } = useAuth();

  return currentUser ? (
    <DashboardLayout>
      <Outlet />
    </DashboardLayout>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;

// <Route
//         {...rest}
//         render={(props) => {
//           return currentUser ? (
//             <Component {...props} />
//           ) : (
//             <Navigate to="/login" />
//           );
//         }}
//       />
