import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet
} from '@react-pdf/renderer';
import numeral from 'numeral';
import Logo from '../img/TheEmeraldLeafLogoHigh-removebg-preview (1).png';

const COL1_WIDTH = 60;
const COLN_WIDTH = (100 - COL1_WIDTH) / 2;

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    padding: 24
  },
  h4: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.235
  },
  h6: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.6
  },
  subtitle2: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.57
  },
  body2: {
    fontSize: 10,
    lineHeight: 1.43
  },
  gutterBottom: {
    marginBottom: 4
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  brand: {
    height: 50,
    width: 80
  },
  company: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 32
  },
  references: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 32
  },
  billing: {
    marginTop: 32
  },
  items: {
    marginTop: 32
  },
  notes: {
    marginTop: 32
  },
  table: {
    display: 'flex',
    width: 'auto'
  },
  tableHeader: {},
  tableBody: {},
  tableRow: {
    borderBottomWidth: 1,
    borderColor: '#EEEEEE',
    borderStyle: 'solid',
    flexDirection: 'row'
  },
  tableCell1: {
    padding: 6,
    width: `${COL1_WIDTH}%`
  },
  tableCellN: {
    padding: 6,
    width: `${COLN_WIDTH}%`
  },
  alignRight: {
    textAlign: 'right'
  }
});

export const InvoicePDF = ({ order, customer, lineItems }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View>
            <Image source={Logo} style={styles.brand} />
            <Text style={styles.h6}>www.theemeraldleaf.com</Text>
          </View>
          <View>
            <Text style={styles.h4}>{order.status}</Text>
            <Text
              style={styles.subtitle2}
            >{`Invoice - ${order.order_id}`}</Text>
            <Text style={styles.subtitle2}>{`Rep - ${order.email}`}</Text>
            <Text
              style={styles.subtitle2}
            >{`Placed On - ${order.order_date}`}</Text>
          </View>
        </View>
        <View style={styles.company}>
          <View>
            <Text style={styles.body2}>Billing</Text>
            <Text style={styles.body2}>8773 West Iliff Lane</Text>
            <Text style={styles.body2}>Lakewood, Colorado 80227</Text>
          </View>
          <View>
            <Text style={styles.body2}>Greenhouse</Text>
            <Text style={styles.body2}>10195 Wadsworth Blvd.</Text>
            <Text style={styles.body2}>Broomfield, Colorado 80021</Text>
          </View>
          <View>
            <Text style={styles.body2}>P: (303) 278-7590</Text>
            <Text style={styles.body2}>F: (303) 986-3688</Text>
            <Text style={styles.body2}>info@theemeraldleaf.com</Text>
          </View>
        </View>
        <View style={styles.references}>
          <View>
            <Text style={[styles.subtitle2, styles.gutterBottom]}>
              Billed to
            </Text>
            <Text style={styles.body2}>{customer.billTo1}</Text>
            <Text style={styles.body2}>{customer.billTo2}</Text>
            <Text style={styles.body2}>{customer.billTo3}</Text>
            <Text style={styles.body2}>{customer.billTo4}</Text>
            <Text style={styles.body2}>{customer.billTo5}</Text>
          </View>
          <View>
            <Text style={[styles.subtitle2, styles.gutterBottom]}>
              Shipped to
            </Text>
            <Text style={styles.body2}>{customer.shipTo1}</Text>
            <Text style={styles.body2}>{customer.shipTo2}</Text>
            <Text style={styles.body2}>{customer.shipTo3}</Text>
            <Text style={styles.body2}>{customer.shipTo4}</Text>
            <Text style={styles.body2}>{customer.shipTo5}</Text>
          </View>
        </View>
        <View style={styles.items}>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell1}>
                  <Text style={styles.h6}>Description</Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={styles.h6}>Qty. O</Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={styles.h6}>Qty. R</Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={styles.h6}>Unit Price</Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.h6, styles.alignRight]}>Total</Text>
                </View>
              </View>
            </View>
            <View style={styles.tableBody}>
              {(lineItems || []).map((item) => (
                <View style={styles.tableRow} key={item.id}>
                  <View style={styles.tableCell1}>
                    <Text style={styles.body2}>{item.description}</Text>
                  </View>
                  <View style={styles.tableCellN}>
                    <Text style={styles.body2}>{item.quantity}</Text>
                  </View>
                  <View style={styles.tableCellN}>
                    <Text style={styles.body2}>{item.quantity}</Text>
                  </View>
                  <View style={styles.tableCellN}>
                    <Text style={styles.body2}>
                      {numeral(item.price).format('$0,0.00')}
                    </Text>
                  </View>
                  <View style={styles.tableCellN}>
                    <Text style={[styles.body2, styles.alignRight]}>
                      {numeral(item.total).format('$0,0.00')}
                    </Text>
                  </View>
                </View>
              ))}
              <View style={styles.tableRow}>
                <View style={styles.tableCell1} />
                <View style={styles.tableCellN}>
                  <Text style={styles.body2}>Account Balance</Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.body2, styles.alignRight]}>
                    {numeral(customer.balance).format('$0,0.00')}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell1} />
                <View style={styles.tableCellN}>
                  <Text style={styles.body2}>Subtotal</Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.body2, styles.alignRight]}>
                    Subtotal
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell1} />
                <View style={styles.tableCellN}>
                  <Text style={styles.body2}>Taxes</Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.body2, styles.alignRight]}>
                    Tax Amount
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell1} />
                <View style={styles.tableCellN}>
                  <Text style={styles.body2}>Total</Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.body2, styles.alignRight]}>
                    Total Amount
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.notes}>
          <Text style={[styles.h6, styles.gutterBottom]}>Notes</Text>
          <Text style={styles.body2}>
            Use the top left email to reach out with any questions.
          </Text>
        </View>
      </Page>
    </Document>
  );
};
